import React from "react";
import "./style.scss";
import LockRepairingMetallImage from "../../../../assets/images/services/door-repairing.png";

const LockRepairingMetall = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Проблемы с входной или межкомнатной дверью?</h2>
        <p className="car-opening-paragraph">
          Не спешите открывать каталог и выбирать новое полотно. Мастера команды
          «Моscow-lock» бесплатно проконсультируют и окажут профессиональный
          ремонт имеющейся двери.
        </p>
        <img
          className="car-opening-image"
          src={LockRepairingMetallImage}
          alt="Картинка услуги"
        />
        <p className="car-opening-paragraph">Какие виды работ выполняем?</p>
        <ul>
          <li>
            <h4>Сварка петель</h4>
            <p className="car-opening-paragraph">
              Металлические двери имеют большой вес, и зачастую петли приходят в
              негодность из-за оказываемой на них нагрузки. В таких случаях
              эксперты срежут крепления и наварят новые.
            </p>
          </li>
          <li>
            <h4>Сварка покрытия</h4>
            <p className="car-opening-paragraph">
              Внешняя поверхность двери может быть повреждена в процессе
              эксплуатации при попытке взлома. Наши специалисты при помощи
              сварочного аппарата вернут целостность металлической части
              полотна.
            </p>
          </li>
          <li>
            <h4>Регулировка и замена доводчиков</h4>
            <p className="car-opening-paragraph">
              Наиболее частые причины выхода из строя доводчиков —
              разбалансировка механизма, излом или изгиб рычага. Мастера
              закрутят клапан, поменяют крепления или доводчик целиком
            </p>
          </li>
          <li>
            <h4>Регулировка двери</h4>
            <p className="car-opening-paragraph">
              Нередки случаи, когда дверь провисает, не входит в коробку,
              скрипит или самопроизвольно открывается. Эксперты службы
              «Моscow-lock» укрепят узлы саморезами, растянут коробку в
              направлении притворного бруса, произведут расточку ответной части
              или полностью поменяют петли
            </p>
          </li>
          <li>
            <h4>Установка МДФ панелей</h4>
            <p className="car-opening-paragraph">
              Поверхность двери со временем покрывается царапинами, потертостями
              и трещинами. Специалисты помогут с выбором панели и поставят новую
            </p>
          </li>
          <li>
            <h4>Замена уплотнителя</h4>
            <p className="car-opening-paragraph">
              После нескольких лет использования уплотнитель на входной двери
              изнашивается и начинает пропускать воздух. Мастера измерят
              расстояние между полотном и каркасом, подберут подходящий материал
              и приклеят прокладку.
            </p>
          </li>
          <li>
            <h4>Установка, замена глазка.</h4>
            <p className="car-opening-paragraph">
              Иногда так случается, что глазок поцарапался, разбился или вообще
              отсутствует. Наши эксперты учтут толщину двери, выберут
              оптимальный диаметр и тип линзы, поменяют приспособление или
              врежут новое.
            </p>
          </li>
        </ul>

        <p className="car-opening-paragraph">
          Команда «Моscow-lock» — это специалисты с 10-летним опытом, которые
          бесплатно выезжают в любую точку Москвы и московской области.
        </p>
        <p className="car-opening-paragraph">
          Для вызова мастера звоните по телефону +7 (968)711-02-40.
        </p>
      </section>
    </main>
  );
};

export default LockRepairingMetall;
