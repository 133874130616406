import React from "react";
import "./style.scss";
import OverlaysImage1 from "../../../../assets/images/services/overlays.png";

const LocksInstallationOverlays = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Установка замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка броненаклаки на замок</h2>
        <p className="car-opening-paragraph">
          Установка брони или броненакладки на замок повышает уровень защиты
          входной двери, придает уверенность в ее неприступности для
          злоумышленников.
        </p>

        <p className="car-opening-paragraph">
          Броненакладка на замок - это шайба, отлитая из прочного закаленного
          сплава металлов. Она защищает корпус и цилиндр замка от силовых
          способов вскрытия:
        </p>
        <ul>
          <li>выбивания</li>
          <li>высверливания</li>
          <li>излома</li>
          <li>вытягивания</li>
          <li>вырывания механизма</li>
        </ul>
        <img
          className="car-opening-image"
          src={OverlaysImage1}
          alt="Картинка услуги"
        />
        <p className="car-opening-paragraph">
          Бронированная накладка состоит из двух металлических пластин - внешней
          и внутренней, стянутых винтами, насквозь проходящими через дверное
          полотно и корпус замка. По способу установки броненакладки делятся на
          врезные и накладные модели.
        </p>
        <h3>Как устанавливается броненакладка?</h3>
        <p className="car-opening-paragraph">
          Установить броненакладку можно на любую дверь, главное чтобы корпус
          замка предусматривал установочные отверстия для монтажа!
        </p>
        <p className="car-opening-paragraph">
          Монтаж врезной броненакладки требует высверливания круглого
          технологического отверстия внешнего листа металла. Проделанное
          отверстие по диаметру должно идеально совпадать с размером углубления
          внешней стороны брони. Самый сложный момент установки - не
          промахнуться с размером отверстия.
        </p>
        <p className="car-opening-paragraph">
          Главное, чтобы между бронированной накладкой и краем вырезанного
          дверного полотна не осталось зазора - это не позволит выдавить, выбить
          ее подручными средствами, сильно осложнит работу взломщика в попытке
          добраться до цилиндра замка.
        </p>
        <h4>
          Наши специалисты могут с ювелирной точностью установить броненакладку
          на вашу дверь!
        </h4>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default LocksInstallationOverlays;
