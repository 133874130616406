import React from "react";
import "./style.scss";
import LocksInstallingImage2 from "../../../../assets/images/services/locks-installing1.png";

const LocksInstallationEntrance = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Установка замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка замков на входную дверь</h2>
        <p className="car-opening-paragraph">
          Специалисты компании устанавливают замки на входные железные двери,
          включая бронированные, кодовые и секретные механизмы с гарантией.
          Работы выполняем по фиксированной цене, приезжаем на дом с новыми
          замками, ручками. Время выезда мастера по Москве менее
          часа, поэтому можно доверить срочное решение проблемы нашему
          профессионалу.
        </p>
        <img
          className="car-opening-image"
          src={LocksInstallingImage2}
          alt="Картинка услуги"
        />

        <p className="car-opening-paragraph">
          Специалистам службы не раз приходилось устанавливать замки во входные
          двери со срочным выездом на квартиру или частный дом. Монтажные работы
          проводятся тогда, когда нужно поставить дополнительный замок или
          задвижку. Однако чаще, необходимость вызова мастера возникает из-за
          интенсивной эксплуатации полотна:
        </p>
        <ul>
          <li>износ старого механизма или заклинивание цилиндра;</li>
          <li>монтаж сомнительного качества, без гарантии;</li>
          <li>
            сложно установить замок на входную дверь вкладным способом или на
            клепки;
          </li>
          <li>попытки взлома или вскрытия квартиры;</li>
          <li>неграмотный выбор размера сменного секрета, цилиндра, ячейки.</li>
        </ul>
        <p className="car-opening-paragraph">
          Если механизм перестает работать, то необходимо действовать. Речь идет
          о безопасности, сохранности имущества. Для наших мастеров установка
          входных замков в Москве ежедневная работа, поэтому мы знаем все
          технические тонкости, недорого усилим взломостойкость замочной
          скважины, устраним проблему на месте.
        </p>
        <h4>
          Цены на установку замков на входную дверь от 1500 до 3000 рублей
        </h4>
        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default LocksInstallationEntrance;
