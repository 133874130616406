import React from "react";
import "./style.scss";
import CarOpeningImage1 from "../../../../assets/images/services/car-opening.png";

const CarOpening = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Вскрытие авто</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h3>Не можете попасть в собственное транспортное средство?</h3>
        <p className="car-opening-paragraph">
          Пытаетесь открыть замок при помощи подручных инструментов?
        </p>
        <img
          className="car-opening-image"
          src={CarOpeningImage1}
          alt="Картинка услуги"
        />
        <p className="car-opening-paragraph">
          Не рекомендуем решать данную проблему самостоятельно. Это может
          привести к дефектам лакокрасочного покрытия, выходу из строя
          электроники или повреждению замочной скважины. Обращайтесь в нашу
          службу! Квалифицированные мастера помогут вам вскрыть дверь автомобиля
          при любых обстоятельствах:
        </p>
        <ul>
          <li>неисправность замка</li>
          <li>потеря ключа</li>
          <li>поломка сигнализации</li>
          <li>блокировка средства передвижения с ключами внутри</li>
          <li>обледенение и промерзание дверного механизма</li>
        </ul>

        <p className="car-opening-paragraph">Мы гарантируем:</p>
        <ul>
          <li>Приезд специалиста в любую точку Москвы и московской области.</li>
          <li>Ожидание эксперта не более 20—30 минут</li>
          <li>Принятие заявок по отечественным и иностранным авто.</li>
          <li>Выполнение работы без царапин и вмятин на кузове</li>
          <li>Изготовление ключей</li>
        </ul>
        <p className="car-opening-paragraph">
          Услуга по вскрытию двери транспортного средства является законной и
          безопасной. Наш мастер окажет вам помощь при наличии документов,
          подтверждающих право собственности.
        </p>

        <p className="car-opening-paragraph">Стоимость:</p>
        <ul>
          <li>Отечественные авто — от 1500 р.</li>
          <li>«Иномарки» — от 2000 р</li>
          <li>Бизнес-класс — от 3000 р.</li>
        </ul>

        <p className="car-opening-paragraph">
          Автомобильные технологии не стоят на месте, автопроизводители
          выпускают современные модели со встроенной двойной блокировкой. Если
          разбить боковое стекло, то вскрыть машину не получится – электронная
          система защиты заблокирует внутренние ручки двери. Современный
          профессиональный инструмент в арсенале наших мастеров, позволяет
          производить вскрытие без повреждения.
        </p>
        <h3>Сколько стоит вскрытие автомобилей без ключа?</h3>
        <ul>
          <li>Отечественные автомобили от 1500 руб</li>
          <li>Открыть иномарку без ключа от 2000 руб</li>
          <li>Бизнес-класс от 3000 руб</li>
        </ul>
        <p className="car-opening-paragraph">Звоните по номеру <a href="tel:+79687110240">+7(968)711-02-40</a> и мы быстро прибудем к месту расположения автомобиля.</p>
      </section>
    </main>
  );
};

export default CarOpening;
