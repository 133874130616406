import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./style.scss";
import { paginationData } from "./data";

const LocksMed = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  return (
    <main className="locks-min-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Замки Комфорт-класса</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Каталог замков комфорт-класса(дополнить)</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Замки Комфорт-класса - это оптимальное решение по соотношению
            цена/качество. Возможность установки дополнительной защиты
            (бронированных накладок, марганцевых пластин), выбора комплектации,
            в зависимости от пожеланий (ручки, цилиндры). Средняя ценовая
            политика - от 3500 рублей.
          </p>
          <p className="catalog-locks-paragraph">
            Замена замка Комфорт-класса предполагает на выбор цилиндра
            европейского стандарта разных размеров, установку броненакладок,
            фурнитуру для входной металлической двери. Их сложно отличить от
            именитых моделей ведущих производителей, предлагаемых покупателю в
            несколько раз дороже. Служат данные механизмы достаточно долго, при
            правильной установке и эксплуатации.
          </p>
          <div className="my-pagination">
            <ul className="pagination-page">
              {paginationData[paginationPage - 1].map((dataItem) => (
                <li className="pagination-page-list-item">
                  <img
                    src={dataItem.imgSrc}
                    className="catalog-lock-image"
                    alt="Замок комфорт-класса"
                  />
                  <div className="catalog-lock-item-info">
                    <h3 className="catalog-lock-item-info-title">
                      {dataItem.lockTitle}
                    </h3>
                    <ul className="catalog-lock-item-info-properties">
                      {dataItem.props.map((prop) => (
                        <li>{prop}</li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              className="catalog-pagination"
              page={paginationPage}
              onChange={(event, value) => {
                setPaginationPage(value);
              }}
              count={paginationData.length}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default LocksMed;
