import React from "react";
import "./style.scss";
import CyliderImage1 from "../../../../assets/images/services/cylinder4.png";

const CylinderInstallation = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Цилиндр замка</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка цилиндра замка</h2>
        <p className="car-opening-paragraph">
          Корректная и аккуратная установка цилиндра замка на входную дверь
          позволит срочно сменить ключи. Наши мастера осуществили монтаж сотен
          сердцевин, цилиндров с выездом в квартиры жителей Москвы, чем
          порадовали клиентов, заказавших установку. Чтобы работа прошла гладко,
          без эксцессов, настоятельно советуем не обращаться к неопытным лицам,
          рекомендуем сразу позвонить в нашу службу, надолго забыть о проблеме.
          Благодаря такому подходу вы сохраните время и деньги.
        </p>
        <p className="car-opening-paragraph">
          Чтобы работа прошла гладко, без эксцессов, настоятельно советуем не
          обращаться к неопытным лицам, рекомендуем сразу позвонить в нашу
          службу, надолго забыть о проблеме. Благодаря такому подходу вы
          сохраните время и деньги.
        </p>
        <img
          className="car-opening-image"
          src={CyliderImage1}
          alt="Картинка услуги"
        />

        <p className="car-opening-paragraph">
          Сотрудники нашей компании проконсультируют Вас в вопросе выбора
          цилиндра замка, помогут подобрать максимально удачный вариант для Вас!
        </p>
        <p className="car-opening-paragraph">
          Наши сотрудники оперативно обрабатывают полученные заявки, спешат с
          выездом, причем независимо от того, в каком районе Москвы проживаете.
        </p>
        <p className="car-opening-paragraph">
          Стоимость услуги довольно низкая в сравнении с полной заменой
          запорного механизма.
        </p>
        <p className="car-opening-paragraph">
          Мы относимся к работе по монтажу ячеек, цилиндров с заменой ключей в
          квартире или офисе ответственно, не в потоке набранных заявок, а
          индивидуально.
        </p>
        <h4>Стоимость работ по установке личикнки замка от 1500 рублей</h4>
        <p className="car-opening-paragraph">
          Стоимость услуг зависит от сложности работ
        </p>
      </section>
    </main>
  );
};

export default CylinderInstallation;
