import React from "react";
import "./style.scss";
import GarageOpeningImage1 from "../../../../assets/images/services/garage-opening.png";

const GarageOpening = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Вскрытие гаражей</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Аварийное вскрытие гаража</h2>
        <p className="car-opening-paragraph">
          Частая ситуация - ломается гаражный замок, но мы продолжаем его
          использовать. В конце концов, после поломки открыть гараж уже не
          возможно. А машину нужно иметь под рукой сейчас, необходимо ехать по
          делам. Что же в результате делать?
        </p>
        <p className="car-opening-paragraph">
          Лучше сразу вызвать мастера для вскрытия замков гаража. Наши
          специалисты могут срочно открыть механизм без ключа любого типа
          запирания. Вам необязательно резать петли болгаркой или пытаться
          ломать конструкцию, а потом дополнительно оплачивать ремонт – мы
          сделаем работу без повреждения, быстро и качественно.
        </p>
        <img
          className="car-opening-image"
          src={GarageOpeningImage1}
          alt="Картинка услуги"
        />

        <h3>Аварийное вскрытие сейфов без ключа</h3>
        <p className="car-opening-paragraph">
          Профессионалы затрачивают минимум времени, позволяют клиентам
          избавиться от стресса, забот. Инструменты и оборудование дают
          возможность мастеру открыть без ключа сейф{" "}
          <span className="bold-text">любой сложности</span>. Исключается
          необходимость замены или ремонта. Мастер гарантирует сохранность
          конструкции.
        </p>
        <h4>Стоимость услуги по вскрытию гаража от 900 рублей</h4>
        <p className="car-opening-paragraph">
          Работа на улице в условиях температуры от 0 до -20 градусов повышает
          цену на 500 рублей
        </p>
        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default GarageOpening;
