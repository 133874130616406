import React from "react";
import "./style.scss";
import LockRepairingEntranceImage from "../../../../assets/images/services/door-panel.png";

const LockRepairingEntrance = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт замков входной двери</h2>
        <p className="car-opening-paragraph">
          Заметили, что полотно закрывается не так, как прежде? Лучше сразу
          вызвать мастера, и поручить ремонт дверного замка на металлической
          двери грамотному специалисту.
        </p>
        <p className="car-opening-paragraph">
          Не стоит пытаться самостоятельно починить неисправный замок! Поручите
          ремонт замков входных дверей в Москве нам, и вам не придется
          переживать о своем выборе. Вовремя вызвать мастера на дом – верное
          решение возникшей проблемы
        </p>
        <img
          className="car-opening-image"
          src={LockRepairingEntranceImage}
          alt="Картинка услуги"
        />
        <h3>Ремонт замков входной металлической двери за один выезд на дом</h3>
        <h4>Наши сотрудники могут выполнить:</h4>
        <ul>
          <li>Ремонт заклинивающего замка</li>
          <li>Перекодировку ключей</li>
          <li>Сварочные работы и замену петель</li>
          <li>Устранение перекоса</li>
          <li>Перекос конструкции</li>
          <li>Реставрацию после вскрытия МЧС</li>
        </ul>
        <h4>Звоните! Мы поможем вам!</h4>

        <p className="car-opening-paragraph">
          Мастер срочно приезжает на квартиру к заказчику, в каком бы районе
          Москвы он не находился.
        </p>
        <p className="car-opening-paragraph">
          Стоит отметить, мы выполняем полное обслуживание металлических, ремонт
          входных дверей с бесплатной диагностикой, восстановлением железных со
          сваркой после вскрытия МЧС на пороге квартиры
        </p>
        <h4>Стоимость ремонта зависит от обьема и сложности работ.</h4>
      </section>
    </main>
  );
};

export default LockRepairingEntrance;
