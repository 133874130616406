import React from "react";
import "./style.scss";
import SafeOpeningImage1 from "../../../../assets/images/services/safe-opening.png";

const SafeOpening = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Вскрытие сейфов</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Как вскрыть сейф без повреждения?</h2>
        <p className="car-opening-paragraph">
          Если у вас есть болгарка, кувалда или другой подручный инструмент, Вы
          можете самостоятtльно вскрыть сейф, тем самым превратив его в
          бесполезный кусок железа. Цена хороших моделей высокая, поэтому
          разумнее вызвать профессионала.
        </p>
        <p className="car-opening-paragraph">
          Наша компания предоставляет услуги по вскрытию сейфов. Наши сотрудники
          имеют специальные инструменты, позволяющие оперативно открыть замок.
        </p>
        <img
          className="car-opening-image"
          src={SafeOpeningImage1}
          alt="Картинка услуги"
        />

        <h3>Аварийное вскрытие сейфов без ключа</h3>
        <p className="car-opening-paragraph">
          Профессионалы затрачивают минимум времени, позволяют клиентам
          избавиться от стресса, забот. Инструменты и оборудование дают
          возможность мастеру открыть без ключа сейф{" "}
          <span className="bold-text">любой сложности</span>. Исключается
          необходимость замены или ремонта. Мастер гарантирует сохранность
          конструкции.
        </p>
        <h4>Высокая квалификация позволяет предлагать вам услуги:</h4>
        <ul>
          <li>открытие сейфов без повреждения;</li>
          <li>перекодирование ключей;</li>
          <li>ремонт и восстановление;</li>
          <li>смена паролей;</li>
          <li>разблокировка защиты;</li>
          <li>замена цилиндров замка.</li>
          <li>диагностика механизмов;</li>
        </ul>
        <p className="car-opening-paragraph">
          Наши сотрудники сделают все, чтобы подтвердить статус компании
          высокого уровня обслуживания.
        </p>

        <h3>Процесс вскрытия сейфов</h3>
        <p className="car-opening-paragraph">
          Вызов мастера экстренной службы – то, что нужно, если стремитесь
          свести риск повреждения хранилища к минимуму, сохранить
          конфиденциальность. Наши сотрудники к каждому заказу подходят
          индивидуально, не в свете набранных работ.
        </p>
        <p className="car-opening-paragraph">
          Если необходимо срочно вскрыть сейф без повреждений, мы приедем на
          дом, быстро устраним проблему. Наша цель свести любые неисправности к
          нулю независимо от того, с какими моделями приходится работать,
          механическими или электронными. Если хранилище уже повреждено, мы
          восстановим работоспособность.И неважно, что случилось с механизмом:
          сломался ключ, утрачена секретная комбинация или заклинил замок.
        </p>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default SafeOpening;
