import React from "react";
import "./style.scss";
import CyliderImage1 from "../../../../assets/images/services/cylinder3.png";

const CylinderReplacement = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Цилиндр замка</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Замена цилиндра замка</h2>
        <p className="car-opening-paragraph">
          Замена цилиндра или сердцевины замка входной двери в квартиру, если в
          ней сломался ключ, у новичка может вызвать массу вопросов. Где взять
          инструменты? Нужно ли полностью разбирать запорный механизм? Какой
          фирмы цилиндр, ячейка, сердечник подойдет по размерам? Не советуем
          брать на себя ответственность, если не разбираетесь в профильной
          работе. В Москве, гораздо выгоднее поручить замену грамотному мастеру.
          Специалист аккуратно демонтирует старый цилиндр из замка,
          модернизирует запорный механизм и установит новый элемент за один
          выезд.
        </p>

        <h4>Возможно вы столкнулись с одной из следующих проблем:</h4>
        <ul>
          <li>Цилиндр заклинило</li>
          <li>Потеря ключей от замка</li>
          <li>Переезд на новую квартиру</li>
          <li>Замена цилиндра замка после квартирантов</li>
        </ul>
        <h4>
          Звоните нам! Мы поможем решить вашу проблему быстро и качественно!
        </h4>
        <img
          className="car-opening-image"
          src={CyliderImage1}
          alt="Картинка услуги"
        />

        <h3>Бесплатный выезд мастеров для замены цилиндра замка</h3>
        <p className="car-opening-paragraph">
          Если вы потеряли ключи, вам не нужно приобретать новый дорогой
          механизм. Вы звоните в наш сервис и мастер выезжает на объект. Слесарь
          подъедет на вызов в удобное для клиента время с набором фурнитуры и
          инструментов. Конфиденциальность и порядочность со стороны мастера
          гарантирована, так как мы дорожим своей репутацией и доверием
          заказчиков.
        </p>

        <h4>Стоимость замены цилиндра замка начинается с 1500 рублей</h4>
        <p className="car-opening-paragraph">
          При необходимости специалист проконсультирует вас, подскажет какая
          модель лучше и почему. Мы готовы установить цилиндр с перфорированным
          и английским ключом, механизмы высокой степени секретности.
        </p>
      </section>
    </main>
  );
};

export default CylinderReplacement;
