import React from "react";
import "./style.scss";
import LatchOpeningImage1 from "../../../../assets/images/services/latch-opening.png";



const LatchOpening = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Вскрытие щеколды</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Аварийное вскрытие щеколды или защёлки</h2>
        <p className="car-opening-paragraph">
          Закрылась задвижка железной двери изнутри и не знаете куда звонить?
          Наша компания предлагает Вам рациональное решение!
        </p>
        <p className="car-opening-paragraph">
          Прежде чем портить дверь и пытаться отрыть щеколду или задвижку
          снаружи, лучше позвонить нашим мастерам. Это позволит избежать
          дополнительных затрат. Без практических навыков невозможно осуществить
          вскрытие щеколды на металлической входной двери успешно, быстро и
          грамотно.
        </p>
        <img
          className="car-opening-image"
          src={LatchOpeningImage1}
          alt="Картинка услуги"
        />

        <h3>Стоимость вскрытия щеколды или ночной задвижки от 900 рублей</h3>

        <p className="car-opening-paragraph">
          Оградите себя от ряда беспокойств и проблем, вызовите мастера
          сервисной службы, поскольку он:
        </p>
        <ul>
          <li>быстро приедет на объект</li>
          <li>привезет все инструменты с собой</li>
          <li>знает способы открытия без повреждения</li>
          <li>срочно откроет замок без ключа</li>
          <li>окажет мобильную помощь, проконсультирует</li>
          <li>выполнит работы за доступную цену</li>
        </ul>
        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default LatchOpening;
