import React from "react";
import "./style.scss";
import DoorHandleImage3 from "../../../../assets/images/services/door-handle2.png";

const DoorHandleReplacement = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Дверные ручки</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Нужна замена или установка дверной ручки?</h2>
        <p className="car-opening-paragraph">
          Специалисты команды «Моscow-lock» оказывают квалифицированную помощь в
          течение 30 мин.
        </p>
        <img
          className="car-opening-image"
          src={DoorHandleImage3}
          alt="Картинка услуги"
        />
        <h3>Мастера меняют ручку, если:</h3>
        <ul>
          <li>отходит или отпадает основание</li>
          <li>не работает защелка</li>
          <li>язычок не входит в корпус замка</li>
          <li>ручка не возвращается в нужное положение после нажатия</li>
          <li>заржавел механизм и др</li>
        </ul>
        <h3>Эксперты нашей службы работают со следующими видами дверей</h3>
        <ul>
          <li>стальные</li>
          <li>деревянные</li>
          <li>шпонированные</li>
          <li>стеклянные</li>
          <li>пластиковые</li>
        </ul>
        <h3>Специалисты устанавливают ручки:</h3>
        <ul>
          <li>поворотные</li>
          <li>стационарные</li>
          <li>нажимные</li>
          <li>с замками</li>
        </ul>
        <p className="car-opening-paragraph">
          Наша команда учитывает материал дверного полотна, предназначение
          помещения, конструкцию и тип ручки. Это гарантирует продолжительную
          эксплуатацию и качественную работу механизма.
        </p>
        <p className="car-opening-paragraph">
          Для вызова мастера звоните по телефону <a href="tel:+79687110240">+7(968)711-02-40</a>. Приедем в
          любую точку Москвы и области.
        </p>
      </section>
    </main>
  );
};

export default DoorHandleReplacement;
