import Apecs1425 from "../../../assets/images/catalog/lock-med/apecs1425.jpg";
import Border3b9_6 from "../../../assets/images/catalog/lock-med/border3b9-6.jpg";
import Border3b9_8k from "../../../assets/images/catalog/lock-med/border3b9-8k.jpg";
import Kale_kilit from "../../../assets/images/catalog/lock-med/kale-kilit.jpg";
import Kale_kilit1 from "../../../assets/images/catalog/lock-med/kale-kilit1.jpg";
import Krit from "../../../assets/images/catalog/lock-med/krit.jpg";
import Cisa from "../../../assets/images/catalog/lock-med/cisa.jpg";
import Mottura from "../../../assets/images/catalog/lock-med/mottura.jpg";
import Mettem_3b8 from "../../../assets/images/catalog/lock-med/mettem3b8.jpg";
import Gardian from "../../../assets/images/catalog/lock-med/gardian.jpg";
import Ritko from "../../../assets/images/catalog/lock-med/ritko.jpg";

export const paginationData = [
  [
    {
      imgSrc: Apecs1425,
      lockTitle: "APECS 1425",
      props: [
        "Цвет ручек: хром, латунь, медь, бронза",
        "Механизм: цилиндровый",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, ручки",
        "Способ установки: врезной",
        "Особенности: цилиндрический механизм на выбор!",
      ],
    },
    {
      imgSrc: Border3b9_6,
      lockTitle: "BORDER ЗВ9-6",
      props: [
        "Цвет ручек: латунь",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, ручки",
        "Способ установки: врезной",
      ],
    },
    {
      imgSrc: Border3b9_8k,
      lockTitle: "BORDER ЗВ9-8К",
      props: [
        "Цвет ручек: медный антик",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, ручки",
        "Способ установки: врезной",
      ],
    },
  ],
  [
    {
      imgSrc: Kale_kilit,
      lockTitle: "KALE KILIT",
      props: [
        "Цвет ручек: латунь",
        "Механизм: цилиндрический",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, ручки",
        "Способ установки: врезной",
        "Особенности: цилиндрический механизм на выбор!",
      ],
    },
    {
      imgSrc: Kale_kilit1,
      lockTitle: "KALE KILIT",
      props: [
        "Цвет ручек: латунь",
        "Механизм: цилиндрический",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ручки на выбор, броне накладка на выбор",
        "Способ установки: врезной",
        "Особенности: цилиндрический механизм на выбор",
      ],
    },
    {
      imgSrc: Krit,
      lockTitle: "КРИТ",
      props: [
        "Цвет ручек: хром",
        "Механизм: цилиндрический",
        "Количество ключей: 5 шт",
        "Комплектация: замок, комплект ручек на планке",
        "Способ установки: врезной",
        "Особенности: цилиндрический механизм на выбор"
      ],
    },
  ],
  [
    {
      imgSrc: Cisa,
      lockTitle: "CISA (Италия)",
      props: [
        "Цвет накладки: латунь",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
    {
      imgSrc: Mottura,
      lockTitle: "MOTTURA (Италия)",
      props: [
        "Цвет накладки: латунь",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: замок, ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
    {
      imgSrc: Mettem_3b8,
      lockTitle: "МЕТТЭМ ЗВ8 с задвижкой",
      props: [
        "Цвет накладки: хром",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: замок, задвижка, накладки",
        "Способ установки: врезной",
      ],
    },
  ],
  [
    {
      imgSrc: Gardian,
      lockTitle: "ГАРДИАН",
      props: [
        "Цвет накладки: хром",
        "Механизм: сувальдный",
        "Количество ключей: 4 шт",
        "Комплектация: замок, задвижка, накладки",
        "Способ установки: врезной",
        "Особенности: ручки на выбор",
      ],
    },
    {
      imgSrc: Ritko,
      lockTitle: "RITKO (КРИТ-М)",
      props: [
        "Цвет: хром, латунь",
        "Механизм: цилиндровый",
        "Количество ключей: 5 шт",
        "Комплектация: замок",
        "Способ установки: врезной",
        "Особенности: ручки, броне накладка на выбор",
      ],
    },
  ],
];
