import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import AppHeader from "../AppHeader";
import MainPage from "../MainPage";
import AppFooter from "../AppFooter";
import ServicesPage from "../ServicesPage";
import CatalogPage from "../CatalogPage";
import DoorOpening from "../Services/Opening/DoorOpening";
import CarOpening from "../Services/Opening/CarOpening";
import "./style.scss";
import LocksMin from "../Catalogs/LocksMin";
import OurWorksPage from "../OurWorksPage";
import LocksMed from "../Catalogs/LocksMed";
import LocksVip from "../Catalogs/LocksVip";
import LockCylinder from "../Catalogs/LockCylinder";
import Overlays from "../Catalogs/Overlays";
import DoorHandle from "../Catalogs/DoorHandle";
import SafeOpening from "../Services/Opening/SafeOpening";
import GarageOpening from "../Services/Opening/GarageOpening";
import LatchOpening from "../Services/Opening/LatchOpening";
import LocksReplacementMetall from "../Services/LockReplacement/MetallDoorReplacement";
import LocksReplacementSteel from "../Services/LockReplacement/SteelDoorReplacement";
import LocksReplacementEntrance from "../Services/LockReplacement/EntranceDoorReplacement";
import DoorHandleInstallation from "../Services/DoorHandle/Installation";
import DoorHandleRepairing from "../Services/DoorHandle/Repairing";
import DoorHandleReplacement from "../Services/DoorHandle/Replacement";
import LocksInstallationMetall from "../Services/LocksInstallation/MetallDoor";
import LocksInstallationEntrance from "../Services/LocksInstallation/EntranceDoor";
import LocksInstallationOverlays from "../Services/LocksInstallation/Overlays";
import LocksInstallationSteel from "../Services/LocksInstallation/SteelDoor";
import CylinderInstallation from "../Services/LockCylinder/Installation";
import CylinderReplacement from "../Services/LockCylinder/Replacement";
import CylinderRepairing from "../Services/LockCylinder/Repairing";
import DoorRepairingHinges from "../Services/DoorRepairing/DoorHinges";
import DoorRepairingSteel from "../Services/DoorRepairing/SteelDoor";
import DoorRepairingEntrance from "../Services/DoorRepairing/EntranceDoor";
import DoorRepairingMetall from "../Services/DoorRepairing/MetallDoor";
import LockRepairingEntrance from "../Services/LockRepairing/EntranceDoor";
import LockRepairingMetall from "../Services/LockRepairing/MetallDoor";
import LockRepairingSteel from "../Services/LockRepairing/SteelDoor";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AppHeader />
        <Switch>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route path="/services" exact>
            <ServicesPage />
          </Route>
          <Route path="/services/door-opening" exact>
            <DoorOpening />
          </Route>
          <Route path="/services/car-opening" exact>
            <CarOpening />
          </Route>
          <Route path="/services/safe-opening" exact>
            <SafeOpening />
          </Route>
          <Route path="/services/garage-opening" exact>
            <GarageOpening />
          </Route>
          <Route path="/services/latch-opening" exact>
            <LatchOpening />
          </Route>
          <Route path="/services/locks-replacement-metall" exact>
            <LocksReplacementMetall />
          </Route>
          <Route path="/services/locks-replacement-steel" exact>
            <LocksReplacementSteel />
          </Route>
          <Route path="/services/locks-replacement-entrance" exact>
            <LocksReplacementEntrance />
          </Route>
          <Route path="/services/door-handle-installation" exact>
            <DoorHandleInstallation />
          </Route>
          <Route path="/services/door-handle-repairing" exact>
            <DoorHandleRepairing />
          </Route>
          <Route path="/services/door-handle-replacement" exact>
            <DoorHandleReplacement />
          </Route>
          <Route path="/services/locks-installation-metall" exact>
            <LocksInstallationMetall />
          </Route>
          <Route path="/services/locks-installation-entrance" exact>
            <LocksInstallationEntrance />
          </Route>
          <Route path="/services/locks-installation-steel" exact>
            <LocksInstallationSteel />
          </Route>
          <Route path="/services/locks-installation-overlays" exact>
            <LocksInstallationOverlays />
          </Route>
          <Route path="/services/cylinder-replacement" exact>
            <CylinderReplacement />
          </Route>
          <Route path="/services/cylinder-installation" exact>
            <CylinderInstallation />
          </Route>
          <Route path="/services/cylinder-repairing" exact>
            <CylinderRepairing />
          </Route>
          <Route path="/services/door-repairing-steel" exact>
            <DoorRepairingSteel />
          </Route>
          <Route path="/services/door-repairing-hinges" exact>
            <DoorRepairingHinges />
          </Route>
          <Route path="/services/door-repairing-metall" exact>
            <DoorRepairingMetall />
          </Route>
          <Route path="/services/door-repairing-entrance" exact>
            <DoorRepairingEntrance />
          </Route>
          <Route path="/services/locks-repairing-metall" exact>
            <LockRepairingMetall />
          </Route>
          <Route path="/services/locks-repairing-entrance" exact>
            <LockRepairingEntrance />
          </Route>
          <Route path="/services/locks-repairing-steel" exact>
            <LockRepairingSteel />
          </Route>

          <Route path="/catalog" exact>
            <CatalogPage />
          </Route>
          <Route path="/catalog/locks-min" exact>
            <LocksMin />
          </Route>
          <Route path="/catalog/locks-med" exact>
            <LocksMed />
          </Route>
          <Route path="/catalog/locks-vip" exact>
            <LocksVip />
          </Route>
          <Route path="/catalog/lock-cylinder" exact>
            <LockCylinder />
          </Route>
          <Route path="/catalog/overlays" exact>
            <Overlays />
          </Route>
          <Route path="/catalog/door-handle" exact>
            <DoorHandle />
          </Route>
          <Route path="/portfolio" exact>
            <OurWorksPage />
          </Route>
        </Switch>
        <AppFooter />
      </BrowserRouter>
    </>
  );
};

export default App;
