import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./style.scss";
import { paginationData } from "./data";

const LockCylinder = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  return (
    <main className="locks-min-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Цилиндры для замков</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Цилиндры для замков</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Профильные цилиндры предохраняют замок от высверливания, но не
            защищают от выбивания или вытягивания. Поэтому настоятельно
            рекомендуем устанавливать цилиндры для замков вместе с
            броненакладкой.
          </p>
          <div className="my-pagination">
            <ul className="pagination-page">
              {paginationData[paginationPage - 1].map((dataItem) => (
                <li className="pagination-page-list-item">
                  <img
                    src={dataItem.imgSrc}
                    className="catalog-lock-image"
                    alt="Цилиндр"
                  />
                  <div className="catalog-lock-item-info">
                    <h3 className="catalog-lock-item-info-title">
                      {dataItem.lockTitle}
                    </h3>
                    <ul className="catalog-lock-item-info-properties">
                      {dataItem.props.map((prop) => (
                        <li>{prop}</li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              className="catalog-pagination"
              page={paginationPage}
              onChange={(event, value) => {
                setPaginationPage(value);
              }}
              count={paginationData.length}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default LockCylinder;
