import React from "react";
import "./style.scss";
import LocksInstallationSteelImage from "../../../../assets/images/services/locks-replacement3.png";

const LocksInstallationSteel = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Установка замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка замков в пластиковую дверь</h2>
        <p className="car-opening-paragraph">
          Наша организация оказывает услуги по установке замков в пластиковую дверь
          с выездом мастера в любую точку Москвы. Сотрудники фирмы готовы
          приехать в удобное для Вас время, с набором качественных взломостойких
          механизмов на Ваш выбор.
        </p>
        <p className="car-opening-paragraph">
          Сотрудники нашей компании проконсультируют Вас в вопросе выбора замка,
          помогут подобрать максимально удачный вариант для Вас!
        </p>
        <img
          className="car-opening-image"
          src={LocksInstallationSteelImage}
          alt="Картинка услуги"
        />
        <h4>Возможно вы столкнулись с одной из следующих проблем:</h4>
        <ul>
          <li>Поломка старого замка</li>
          <li>Взлом замка злоумешленниками</li>
          <li>Потеря ключей от замка</li>
          <li>Переезд на новую квартиру</li>
          <li>Замена замка после квартирантов</li>
        </ul>
        <h4>
          Звоните нам! Мы поможем решить вашу проблему быстро и качественно!
        </h4>

        <h3>
          Бесплатный выезд мастеров для установки замка в металлическую дверь
        </h3>
        <p className="car-opening-paragraph">
          Профессиональный монтаж запорных устройств имеет свои особенности. В
          зависимости от типа механизма, процедура монтажа отличается разными
          инструкциями завода производителя. Мастера дверного сервиса
          устанавливают базовые, комбинированные и дополнительные устройства.
          Самая высокая степень защиты принадлежит элементам зарубежного
          производства.
        </p>
        <h4>Обратите внимание на плюсы работы с нами:</h4>
        <ul>
          <li>Доступны 24 часа в сутки!</li>
          <li>
            Работаем без выходных, ведь замена замка может потребоваться в любой
            день
          </li>
          <li>
            Качественно! Вы не скоро обратитесь к нам повторно. Мастер
            использует исключительно надежные механизмы.
          </li>
          <li>Незамедлительно выезжаем на заказ сразу после вашего звонка!</li>
        </ul>
        <h4>Стоимость работ по установке замка от 2500 рублей</h4>
      </section>
    </main>
  );
};

export default LocksInstallationSteel;
