import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./style.scss";
import { paginationData } from "./data";

const LocksMin = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  return (
    <main className="locks-min-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Бюджетные замки</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Каталог замков эконом-класса</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Сегодня распространено мнение, что хороших, но одновременно
            недорогих замков не бывает, а все что представлено отечественными
            производителями не выдерживает никакой критики.
          </p>
          <p className="catalog-locks-paragraph">
            Однако прогресс не стоит на месте. Поэтому замочно-скобяная
            продукция теперь изготавливается на импортном оборудовании, с
            соблюдением стандартов качества.
          </p>
          <p className="catalog-locks-paragraph">
            Одновременно с качеством, цена на замки остается
            конкурентноспособной с импортными аналогами, за счет производства в
            СНГ, не требующей существенных вложений в перевозку и растаможивание
            продукции.
          </p>
          <p className="catalog-locks-paragraph">
            Ценовая политика от 2000 рублей - складывается из стоимости самого
            замка и способа замены, установки или врезки замка, а так же
            конструктивных особенностей двери.
          </p>
          <div className="my-pagination">
            <ul className="pagination-page">
              {paginationData[paginationPage - 1].map((dataItem) => (
                <li className="pagination-page-list-item">
                  <img
                    src={dataItem.imgSrc}
                    className="catalog-lock-image"
                    alt="Бюджетный замок"
                  />
                  <div className="catalog-lock-item-info">
                    <h3 className="catalog-lock-item-info-title">
                      {dataItem.lockTitle}
                    </h3>
                    <ul className="catalog-lock-item-info-properties">
                      {dataItem.props.map((prop) => (
                        <li>{prop}</li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              className="catalog-pagination"
              page={paginationPage}
              onChange={(event, value) => {
                setPaginationPage(value);
              }}
              count={paginationData.length}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default LocksMin;
