import React from "react";
import "./style.scss";
import DoorHandleImage1 from "../../../../assets/images/services/door-handle.png";

const DoorHandleInstallation = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Дверные ручки</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка дверных ручек</h2>
        <p className="car-opening-paragraph">
          В нашей компании вы можете заказать ручку на ваш вкус. Мы работаем с
          дверными ручками различных брендов. Вы точно сможете найти то, что
          подходит именно вам.
        </p>
        <p className="car-opening-paragraph">
          Покупка хорошей фурнитуры – это только полдела. Профессиональный
          монтаж дверных ручек – вот, что важно для жителей Москвы и области. В
          противном случае вы можете никогда не узнать о функционале,
          технических возможностях. Заказав комплекс услуг в сервисной службе,
          вы оцените преимущества фурнитуры
        </p>
        <img
          className="car-opening-image"
          src={DoorHandleImage1}
          alt="Картинка услуги"
        />
        <h3>Преимущества монтажа специалистами</h3>
        <p className="car-opening-paragraph">
          Сервисный ремонт, установка дверных ручек с защелкой или без, выезд по
          Москве и области в течение 10 минут с момента обращения под силу
          нашему мастеру. Работаем без выходных, поменять замок или
          отремонтировать железную конструкцию на дому предлагаем недорого, с
          гарантией. Причина поломки всегда одна: нажимная система сломалась
          из-за срока давности или активной эксплуатации.
        </p>
        <p className="car-opening-paragraph">
          Мы заметили, что чаще изнашивается не сам замок, а отрывается ручка.
          Необязательно менять запорный механизм целиком. Можно оставить корпус
          и цилиндр старые, установить новый комплект ручек, минимизировать
          стоимость затрат.
        </p>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default DoorHandleInstallation;
