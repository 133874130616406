import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DoorOpeningImage1 from "../../assets/images/door-opening1.png";
import GarageOpeningImage1 from "../../assets/images/services/garage-opening.png";
import LatchOpeningImage1 from "../../assets/images/services/latch-opening.png";
import SafeOpeningImage1 from "../../assets/images/services/safe-opening.png";
import CarOpeningImage1 from "../../assets/images/services/car-opening.png";

import LocksReplacementImage1 from "../../assets/images/services/locks-replacement1.png";
import LocksReplacementImage2 from "../../assets/images/services/locks-replacement2.png";
import LocksReplacementImage3 from "../../assets/images/services/locks-replacement3.png";

import DoorHandleImage1 from "../../assets/images/services/door-handle.png";
import DoorHandleImage2 from "../../assets/images/services/door-handle1.png";
import DoorHandleImage3 from "../../assets/images/services/door-handle2.png";

import LocksInstallingImage2 from "../../assets/images/services/locks-installing1.png";
import LocksInstallingImage1 from "../../assets/images/services/locks-installing.png";
import OverlaysImage1 from "../../assets/images/services/overlays.png";
import LocksInstallationSteelImage from "../../assets/images/services/locks-replacement3.png";

import CyliderImage1 from "../../assets/images/services/cylinder4.png";
import CyliderImage2 from "../../assets/images/services/cylinder5.png";
import CyliderImage3 from "../../assets/images/services/cylinder3.png";

import DoorHingesImage from "../../assets/images/services/door-hinges.png";
import DoorRepairingEntranceImage from "../../assets/images/services/door-repairing1.png";
import DoorRepairingMetallImage from "../../assets/images/services/door-repairing2.png";

import LockRepairingEntranceImage from "../../assets/images/services/door-panel.png";
import LockRepairingMetallImage from "../../assets/images/services/door-repairing.png";
import LockRepairingSteelImage from "../../assets/images/services/locks-replacement1.png";

const ServicesPage = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <main>
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Услуги</h1>
        </div>
      </section>
      <section className="services-list">
        <Accordion
          expanded={isExpanded}
          onChange={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Вскрытие замков</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-opening"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorOpeningImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Вскрытие дверей
                    </h3>
                    <p className="services-list-block-item-cost">от 900 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/car-opening"
                >
                  <img
                    className="services-list-block-item-image"
                    src={CarOpeningImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Вскрытие автомобилей
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/safe-opening"
                >
                  <img
                    className="services-list-block-item-image"
                    src={SafeOpeningImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Вскрытие сейфов
                    </h3>
                    <p className="services-list-block-item-cost">
                      Цена договорная
                    </p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/garage-opening"
                >
                  <img
                    className="services-list-block-item-image"
                    src={GarageOpeningImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Вскрытие гаражей
                    </h3>
                    <p className="services-list-block-item-cost">от 900 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/latch-opening"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LatchOpeningImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Вскрытие щеколды
                    </h3>
                    <p className="services-list-block-item-cost">от 900 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"

          >
            <h2>Замена замков</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-replacement-entrance"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksReplacementImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Входной двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-replacement-metall"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksReplacementImage2}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Металлической двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-replacement-steel"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksReplacementImage3}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Пластиковой двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Дверные ручки</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-handle-replacement"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorHandleImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Замена</h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-handle-installation"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorHandleImage2}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Установка
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-handle-repairing"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorHandleImage3}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Ремонт</h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Установка замков</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-installation-metall"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksInstallingImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Металлическая дверь
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-installation-entrance"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksInstallingImage2}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Входная дверь
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-installation-steel"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LocksInstallationSteelImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Пластиковая дверь
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-installation-overlays"
                >
                  <img
                    className="services-list-block-item-image"
                    src={OverlaysImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Установка броненакладок
                    </h3>
                    <p className="services-list-block-item-cost">от 1000 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Цилиндр замка</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/cylinder-replacement"
                >
                  <img
                    className="services-list-block-item-image"
                    src={CyliderImage1}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Замена</h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/cylinder-installation"
                >
                  <img
                    className="services-list-block-item-image"
                    src={CyliderImage2}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Установка
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/cylinder-repairing"
                >
                  <img
                    className="services-list-block-item-image"
                    src={CyliderImage3}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Ремонт</h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Ремонт дверей</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-repairing-metall"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorRepairingMetallImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Металлических
                    </h3>
                    <p className="services-list-block-item-cost">
                      Цена договорная
                    </p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-repairing-entrance"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorRepairingEntranceImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Входных</h3>
                    <p className="services-list-block-item-cost">
                      Цена договорная
                    </p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-repairing-steel"
                >
                  <img
                    className="services-list-block-item-image"
                    src={CyliderImage2}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">Пластиковых</h3>
                    <p className="services-list-block-item-cost">
                      Цена договорная
                    </p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/door-repairing-hinges"
                >
                  <img
                    className="services-list-block-item-image"
                    src={DoorHingesImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Ремонт петель
                    </h3>
                    <p className="services-list-block-item-cost">
                      Цена договорная
                    </p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="my-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2>Ремонт замков</h2>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="services-list-block-item">
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-repairing-metall"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LockRepairingMetallImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Металлической двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-repairing-entrance"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LockRepairingEntranceImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Входной двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="services-list-block-item-link"
                  to="/services/locks-repairing-steel"
                >
                  <img
                    className="services-list-block-item-image"
                    src={LockRepairingSteelImage}
                    alt="Иконка услуги"
                  />
                  <div className="services-list-block-item-info">
                    <h3 className="services-list-block-item-title">
                      Пластиковой двери
                    </h3>
                    <p className="services-list-block-item-cost">от 1500 руб</p>
                  </div>
                </Link>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </section>
    </main>
  );
};

export default ServicesPage;
