import React from "react";
import "./style.scss";
import LocksReplacementImage2 from "../../../../assets/images/services/locks-replacement2.png";

const LocksReplacementMetall = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Замена замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Замена дверного замка в Москве и московской области</h2>
        <p className="car-opening-paragraph">
          Замок может выйти из строя по разным причинам:
        </p>
        <ul>
          <li>износ из-за длительной эксплуатации или ржавчины</li>
          <li>смещение деталей относительно друг друга</li>
          <li>нарушение геометрии двери</li>
          <li>потеря ключа</li>
          <li>попытка взлома или вандализм</li>
        </ul>
        <img
          className="car-opening-image"
          src={LocksReplacementImage2}
          alt="Картинка услуги"
        />
        <p className="car-opening-paragraph">
          Во всех описанных ситуациях ремонт следует доверить квалифицированным
          мастерам нашей службы. Ведь от качества выполненных работ зависит
          продолжительность эксплуатации устройства и ваша безопасность.
        </p>
        <p className="car-opening-paragraph">
          Эксперт определит причину поломки и сделает вывод о частичной или
          полной замене замка. Как правило, для установки нового механизма не
          нужно разбирать дверное полотно. Но если потребуется, то наши
          специалисты выполнят работу без царапин и сколов.
        </p>
        <h3>Преимущества команды «Моscow-lock»:</h3>
        <ul>
          <li>
            Меняем замки в межкомнатных и входных дверях в квартирах, складах,
            гаражах и офисах.
          </li>
          <li>Оказываем сервисный ремонт механизмов премиум класса.</li>
          <li>
            Консультируем по вопросу выбора устройства с надёжными
            характеристиками
          </li>
          <li>Устанавливаем сувальдные, цилиндровые, кодовые и другие замки</li>
          <li>Бесплатно выезжаем в любую точку Москвы и области</li>
        </ul>
        <h4>Стоимость услуг:</h4>
        <ul>
          <li>замена на отечественный бренд без разбора двери — от 1000 р.</li>
          <li>замена на импортный бренд с разбором двери — от 2500 р.</li>
        </ul>
        <p className="car-opening-paragraph">
          Звоните по телефону <a href="tel:+79687110240">+7(968)711-02-40</a> и наш специалист
          незамедлительно выедет в назначенное место.
        </p>
      </section>
    </main>
  );
};

export default LocksReplacementMetall;
