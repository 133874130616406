import Atra from "../../../assets/images/catalog/lock-vip/atra.jpg";
import Atra1 from "../../../assets/images/catalog/lock-vip/atra1.jpg";
import Abloy from "../../../assets/images/catalog/lock-vip/abloy.jpg";
import Abloy1 from "../../../assets/images/catalog/lock-vip/abloy1.jpg";
import Cisa from "../../../assets/images/catalog/lock-vip/cisa.jpg";
import Cisa1 from "../../../assets/images/catalog/lock-vip/cisa1.jpg";
import Cisa2 from "../../../assets/images/catalog/lock-vip/cisa2.jpg";
import Abus from "../../../assets/images/catalog/lock-vip/abus.jpg";
import Mottura52 from "../../../assets/images/catalog/lock-vip/mottura52.jpg";
import Mottura54 from "../../../assets/images/catalog/lock-vip/mottura52.jpg";
import Mottura62 from "../../../assets/images/catalog/lock-vip/mottura62.jpg";
import Mottura72 from "../../../assets/images/catalog/lock-vip/mottura72.jpg";
import Mottura85 from "../../../assets/images/catalog/lock-vip/mottura85.jpg";
import Nucleo from "../../../assets/images/catalog/lock-vip/nucleo.jpg";
import MultlockM602 from "../../../assets/images/catalog/lock-vip/mul-t-lock-m602.jpg";
import MultlockOmega from "../../../assets/images/catalog/lock-vip/mul-t-lock-omega.jpg";
import MultlockMatrix from "../../../assets/images/catalog/lock-vip/mul-t-lock-matrix.jpg";
import Securemme25 from "../../../assets/images/catalog/lock-vip/securemme25.jpg";
import Nucleo1 from "../../../assets/images/catalog/lock-vip/nucleo1.jpg";

export const paginationData = [
  [
    {
      imgSrc: Atra,
      lockTitle: "Односистемный замок ATRA",
      props: [
        "Серия HEAVY с одним ключевым механизмом повышенной секретности",
        "Имеет 3 точки запирания",
        "Сувальдный замок ATRA со сменным блоком секретности управляет распорами и ригелями",
        "Механизм предусматривает более 1 млн. секретов",
      ],
    },
    {
      imgSrc: Atra1,
      lockTitle: "Двухсистемный замок ATRA",
      props: [
        "DOUBLE CYLINDRO - зависимый замок со сменным элементом секретности (нуклео)",
        "В комплекте с накладками, две пластины, препятствующие высверливанию, винты для крепления цилиндра",
        "Верхний замок управляет распорами, ригелями и защелкой",
        "Нижний замок имеет один ригель и блокирует верхний замок",
      ],
    },
    {
      imgSrc: Abloy,
      lockTitle: "Замок ABLOY",
      props: [
        "Врезной сувальдный обладает секретностью более 1 млн. различных комбинаций",
        "Корпус замка изготовлен из закаленной стали и дополнительно укреплен броненакладками",
        "Замок оснащен крюкообразным ригелем, который цепляется за ответную часть двери, что препятствует вскрытию двери силовым методом",
      ],
    },
  ],
  [
    {
      imgSrc: Abloy1,
      lockTitle: "Замок ABLOY",
      props: [
        "С ригелем прямой конструкции, косым ригелем из закаленной стали и защитой от высверливания",
        "Прямой ригель конструкции запирается и отпирается посредством ключа или поворотной вертушки",
        "Косой ригель отпирается как ключом, так и с помощью ручки",
        "Ручками комплектуется дополнительно.",
      ],
    },
    {
      imgSrc: Abus,
      lockTitle: "ABUS",
      props: [
        "Можно установить практически на все корпуса замков",
        "Защита цилиндра от выбивания или слома",
        "Свободно вращающийся стальной диск на ручке защищает от высверливания",
        "В данной комплектации предусмотрен цилиндр на выбор",
      ],
    },
    {
      imgSrc: Cisa,
      lockTitle: "Односистемный замок CISA",
      props: [
        "С одним ключевым механизмом повышенной секретности",
        "Полное запирание в 4 оборота",
        "Сувальдный механизм, выпускаются модели замков с функцией перекодировки New Cambio Facile",
        "В зависимости от комплектации 3 или 5 сейфовых ключей",
      ],
    },
  ],
  [
    {
      imgSrc: Cisa1,
      lockTitle: "Двухсистемный замок CISA",
      props: [
        "С механической системой перекодировки",
        "Устанавливается в металлические двери в качестве основного замка.",
        "Комплектуется: корпус замка, набор для перекодировки, монтажный ключ.",
        "Не комплектуется: цилиндром, торцевой планкой, накладками.",
      ],
    },
    {
      imgSrc: Cisa2,
      lockTitle: "Двухсистемный замок CISA",
      props: [
        `Цилиндровый с системой "2 in 1"`,
        "Пять ригелей замка плюс защелка",
        "На данный замок подходят раздельные ручки MSM Lock или Armadillo, рекомендуется броненакладка CISA",
        "Блок замка имеет уши для монтажа вертикальных тяг",
        "Цилиндры от любого европейского производителя",
      ],
    },
    {
      imgSrc: Mottura52,
      lockTitle: "Замки MOTTURA 52 серии",
      props: [
        "Мощные врезной замок предназначен для металлических дверей",
        "Некоторые модели снабжены системой Nucleo Replay (сменный перекодируемый сувальдный механизм)",
        "Количество ключей: 5 шт",
        "Дополнительная комплектация: ручки, защитная марганцевая пластина.",
      ],
    },
  ],
  [
    {
      imgSrc: Mottura54,
      lockTitle: "Замки MOTTURA 54 серии",
      props: [
        "Мощные, врезные замки с защелкой, имеющие три точки запирания",
        "Двухсистемный замок с сувальдными механизмами",
        "Комплектуется двумя наборами, каждый по 5 ключей",
        "Действие главного черного ключа блокирует красный ключ",
        "Дополнительная комплектация: ручки, защитная марганцевая пластина",
      ],
    },
    {
      imgSrc: Mottura62,
      lockTitle: "Замки MOTTURA 62 серии Serrablocca",
      props: [
        "Это сувальдно помповые качественные, взломостойкие и особо мощные замки",
        "Корпус замка усилен бронезащитной пластиной в районе замочной скважины",
        "Изнутри дверь можно открыть или закрыть ручкой",
      ],
    },
    {
      imgSrc: Mottura72,
      lockTitle: "Замки MOTTURA 72 серии",
      props: [
        "Врезной сувальдный замок, не имеющий защелки",
        "Комплектуется 5 ключами, накладками, ответной планкой",
        "Замок Mottura 72 серии дополнительно может быть защищен марганцевой пластиной.",
      ],
    },
  ],
  [
    {
      imgSrc: Mottura85,
      lockTitle: "Замки MOTTURA 85 серии",
      props: [
        "Врезной замок с защелкой, имеет три точки запирания",
        "Замок может комплектоваться ручками,марганцевой пластиной против сверления, евроцилиндром на выбор, броненакладкой для защиты цилиндра",
      ],
    },
    {
      imgSrc: Nucleo,
      lockTitle: "NUCLEO MOTTURA",
      props: [
        "Сменный блок нуклео позволяет при утере ключа менять только элемент секретности замка, а не весь замок.",
        "Гарантия безопасности - заказчик сам распечатывает ключи от нового нуклео замка",
      ],
    },
    {
      imgSrc: MultlockM602,
      lockTitle: "Замки MUL-T-Lock M602",
      props: [
        "Основной замок под ручку с системой четырехканального запирания",
        "Замок имеет два протектора цилиндра, реверсную защелку",
        "Удобно запирается одним оборотом ключа",
        "Установка желательна с комплектом защитной фурнитуры.",
      ],
    },
  ],
  [
    {
      imgSrc: MultlockOmega,
      lockTitle: "Замки MUL-T-Lock OMEGA+",
      props: [
        "Роторные замки OMEGA+ чрезвычайно стойки к попыткам взлома или подбора отмычки",
        "Число секретных комбинаций превышает 10 миллионов",
        "При повороте ключа в двери ротор приводит в движение шестерни, которые управляют ригелями и вертикальными тягами, движущимися независимо друг от друга",
      ],
    },
    {
      imgSrc: MultlockMatrix,
      lockTitle: "Замки MUL-T-Lock MATRIX",
      props: [
        "Перекодируемые запатентованные замки с зубчато-реечным механизмом",
        "Абсолютно бесшумные, устойчивые к взлому, легко перекодируемые",
        "Перекодировка позволит использование новой связки ключей бесконечное количество раз",
      ],
    },
    {
      imgSrc: Securemme25,
      lockTitle: "Замки SECUREMME 25 серии",
      props: [
        "Двухсистемный сувальдно-цилиндровый замок.",
        "Верхняя система-это сувальдный механизм со сменным секретным механизмом (нуклео), нижняя система-это цилиндровый механизм с возможностью установки в нее любого цилиндра евростандарта",
        "Желательна установка броненакладки (дополнительно)",
        "При закрытии нижнего механизма, закрывается отверстие для ключа верхнего механизма",
      ],
    },
  ],
  [
    {
      imgSrc: Nucleo1,
      lockTitle: "NUCLEO SECUREMME",
      props: [
        "Сменный блок нуклео позволяет при утере ключа менять только элемент секретности замка, а не весь замок",
        "Гарантия безопасности - заказчик сам распечатывает ключи от нового нуклео замка",
      ],
    },
  ],
];
