import React from "react";
import "./style.scss";

const DoorHandle = () => {
  return (
    <main className="locks-min-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Дверные ручки</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Ремонт и замена дверных ручек</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Самые надежные замки для металлических дверей Премиум-класса
            представлены на отечественном рынке, в первую очередь, мировыми
            ведущими компаниями замочно-скобяной продукции с высокой степенью
            секретности механизмов, такими как: ATRA, ABUS, CISA, MOTTURA,
            MUL-T-LOCK, KABA, EVVA, SECUREMME и др.
          </p>
          <p className="catalog-locks-paragraph">
            Данная категория предполагает использование исключительно надежных
            замков. Надежные дверные замки данных производителей невозможно
            открыть отмычкой или подбором ключей, количество секретных
            комбинаций подбора доходит до нескольких миллионов.
          </p>
          <p className="catalog-locks-paragraph">
            Цилиндры производятся из кислотно-износостойкого металла и
            оснащены победитовыми стержнями и стальными шариками, защищающими
            механизм замка от высверливания.
          </p>
          <p className="catalog-locks-paragraph">
            Ценовая политика - от 7000 рублей. Любой каприз за Ваши деньги!
          </p>
        </div>
      </section>
    </main>
  );
};

export default DoorHandle;
