import React from "react";
import "./style.scss";
import DoorHandleImage2 from "../../../../assets/images/services/door-handle1.png";

const DoorHandleRepairing = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Дверные ручки</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт дверных ручек</h2>
        <p className="car-opening-paragraph">
          Поручите ремонт дверной ручки металлической двери в Москве мастерам, и
          выбросьте из головы все бытовые заботы. Наши специалисты
          отремонтировали крепления и переставили на стяжки сотни моделей
          замков, благодаря чему клиенты сэкономили немало денег на стоимости
          новой ручки.
        </p>
        <img
          className="car-opening-image"
          src={DoorHandleImage2}
          alt="Картинка услуги"
        />
        <p className="car-opening-paragraph">
          Хотя сломанные ручки, как правило, меняются на новые, все же во многих
          случаях крепления можно починить. На уличные или двери в квартиру
          устанавливают стационарные и нажимные модели. Если первые используются
          для жесткого открытия металлической двери, то вторые выступают в роли
          защелки запорного механизма.
        </p>
        <p className="car-opening-paragraph">
          Наиболее частые проблемы клиентов:
        </p>
        <ul>
          <li>Выпадает дверная ручка.</li>
          <li>Вышел из строя внутренний квадрат.</li>
          <li>Не открывается защелка в результате износа.</li>
          <li>Ручка не возвращается в исходное положение.</li>
          <li>Повреждено стопорное кольцо</li>
          <li>Отлетел, сломался поворотник на входной двери.</li>
        </ul>

        <p className="car-opening-paragraph">
          На металлической двери ремонт ручки обязателен с момента возникновения
          проблем. Ведь в этом случае владелец квартиры рискует, стать легкой
          добычей для злоумышленников. Если вы обнаружили тревожные симптомы, не
          игнорируйте их в надежде, что функциональный элемент еще не сломан.
        </p>

        <p className="car-opening-paragraph">
          Открывать железную дверь квартиры со сломанной ручкой одно мучение.
          Лучше сразу отремонтировать ее, пока это еще возможно. Если затягивать
          с починкой, то придется менять ее на новую, а значит нести
          дополнительные финансовые потери.
        </p>
        <h4>Стоимость ремонта дверной ручки: 2000 рублей</h4>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default DoorHandleRepairing;
