import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import "./style.scss";
import MainPageCard from "../MainPageCard";

import LockOpening from "../../assets/images/lock-blur1.jpg";
// import LockOpening from "../../assets/images/lock-1.jpeg";

import CarOpening from "../../assets/images/car-lock-blur.jpg";
import LockReplacement from "../../assets/images/lock-replacement1.jpg";
import HandleReplacement from "../../assets/images/door-handle1.jpg";
import LockRepairing from "../../assets/images/lock-repairing.jpg";
import DoorRepairing from "../../assets/images/door1.jpg";
import { Button, Modal, Box, TextField } from "@material-ui/core";
import { Icon } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  p: 4,
};
const btn_styled = {
  position: "fixed",
  top: "90%",
  left: "90%",
  background: "rgba(101, 66, 122, 0.9)",
  color: "white"
}

const MainPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <main className="mainpage-main">
        <section className="top-home-slide">
          <div className="top-text-container">
            <div className="top-text">
              <h1>
                Вскрытие и замена замков.{" "}
                <p className="separate-main-title">
                  Выезд мастера за 15 минут!
                </p>
              </h1>
              <h2 className="top-slide-telephone">
                <span className="shimmer">Работаем 24/7</span>{" "}
                <span className="phone-inline-bg">
                  <a href="tel:+79687110240">+7(968)711-02-40</a>
                </span>
              </h2>
            </div>
          </div>
        </section>

        <section className="service-cards-section">
          <h2 className="service-cards-title">Топ наших услуг</h2>
          <div className="service-cards">
            <div className="service-cards-row">
              {/* <MainPageCard
              title="Вскрытие замков"
              price="от 900"
              toLink="/services/door-opening"
              bgImage={LockOpening} />
            <MainPageCard
              title="Вскрытие авто"
              price="от 1500"
              toLink="/services/car-opening"
              bgImage={CarOpening} />
            <MainPageCard
              title="Замена замков"
              price="от 1000"
              toLink="/services/locks-replacement-metall"
              bgImage={LockReplacement} /> */}
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={LockOpening}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Вскрытие замков
                      </Typography>
                      <Typography variant="body2">от 900р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={CarOpening}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Вскрытие авто
                      </Typography>
                      <Typography variant="body2">от 1500р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={LockReplacement}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Замена замков
                      </Typography>
                      <Typography variant="body2">от 1000р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </div>
            <div className="service-cards-row">
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={HandleReplacement}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Ремонт, замена ручек
                      </Typography>
                      <Typography variant="body2">от 1500р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={LockRepairing}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Ремонт двери
                      </Typography>
                      <Typography variant="body2">от 1000р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              <Link to="/services/door-opening" className="service-card-link">
                <Card sx={{ maxWidth: 345 }} className="service-card-item">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={DoorRepairing}
                      alt="LockOpening"
                    />
                    <CardContent className="service-card-item-content">
                      <Typography gutterBottom variant="h5" component="div">
                        Замена дверного цилиндра
                      </Typography>
                      <Typography variant="body2">от 900р</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              {/*}<MainPageCard
              title="Ремонт, замена ручек"
              price="от 1500"
              toLink="/services/door-handle-replacement"
              bgImage={HandleReplacement}
            />
            <MainPageCard
              title="Ремонт двери"
              price="от 1000"
              toLink="/services/locks-repairing-metall"
              bgImage={LockRepairing}
            />
            <MainPageCard
              title="Замена дверного цилиндра"
              price="от 900"
              toLink="/services/door-repairing-metall"
              bgImage={DoorRepairing}
            /> */}
            </div>
          </div>
        </section>

        <section className="advantages-section">
          <h2 className="advantages-section-title">
            Почему клиенты выбирают нас:
          </h2>
          <ul className="advantages-section-cards">
            <li className="advantages-item">
              <div>
                <h3>Работаем 24/7</h3>
                <p>
                  Наши специалисты готовы приехать по вашему адресу даже ночью в
                  воскресенье!
                </p>
              </div>
            </li>
            <li className="advantages-item">
              <div>
                <h3>Мы по всей Москве</h3>
                <p>
                  У нас есть мастера в каждом районе Москвы и Московской
                  обсласти
                </p>
              </div>
            </li>
            <li className="advantages-item">
              <div>
                <h3>От 500р</h3>
                <p>
                  Цена наших услуг ниже, чем у конкурентов, а качество выше!
                </p>
              </div>
            </li>
            <li className="advantages-item">
              <div>
                <h3>Гарантия качества</h3>
                <p>
                  После выполнения работ вы получаете гантийный талон (3-36
                  месяцев в зависимости от вида услуги)
                </p>
              </div>
            </li>
            <li className="advantages-item">
              <div>
                <h3>Вскрытие без повреждений</h3>
                <p>100% решение проблемы</p>
              </div>
            </li>
            <li className="advantages-item">
              <div>
                <h3>Большой выбор замков и цилиндров</h3>
                <p>
                  Мастер сразу приедет с большим ассортиментом на любой бюджет
                </p>
              </div>
            </li>
          </ul>
        </section>
        <div>
          <Button style={btn_styled} className="btn-style" color="primary" variant="outlined" onClick={handleOpen}>
            {/* <Icon></Icon> */}
            Заказать звонок
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="modal-w">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Оставьте заявку и мы вам позвоним
              </Typography>
              <TextField id="standard-basic" label="Имя" variant="standard" />
              <TextField required id="standard-basic" label="Номер телефона" variant="standard" />
              <TextField id="standard-basic" label="Опишите вашу проблему" variant="standard" />
              <Button >Добавить фото</Button>
              <Button >Отправить</Button>
            </Box>
          </Modal>
        </div>

        {/* <section className="advantages-section">
        <h2 className="advantages-section-title">Оставьте свою заявку</h2>
        
      </section> */}
      </main>
    </>
  );
};
export default MainPage;
