import React from "react";
import "./style.scss";
import LocksInstallingImage1 from "../../../../assets/images/services/locks-installing.png";

const LocksInstallationMetall = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Установка замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Установка замков в металлическую дверь</h2>
        <p className="car-opening-paragraph">
          Установка замков на металлическую дверь с разбором – сложная и
          трудоемкая процедура. Чтобы выполнить ее грамотно и быстро, лучше
          сразу вызвать мастера. В сервисной службе работают обученные
          специалисты, которые прибудут в любой район Москвы. При необходимости
          выполнят монтаж отдельных элементов запорного механизма.
        </p>
        <p className="car-opening-paragraph">
          Изделия поставляются с запечатанными ключами, в специальной упаковке,
          которую заказчик открывает лично
        </p>

        <img
          className="car-opening-image"
          src={LocksInstallingImage1}
          alt="Картинка услуги"
        />

        <p className="car-opening-paragraph">
          Корректный монтаж имеет свои нюансы. Сегодня используют такие типы
          замков как комбинированные, базовые и дополнительные функциональные
          элементы. Наилучшими для считаются изделия врезного типа от зарубежных
          брендов. Стоимость их выше, но качество исполнения – высокое.
        </p>

        <p className="car-opening-paragraph">
          Своим клиентам специалисты компании предлагают комплекс услуг,
          благодаря которому они могут наслаждаться безопасностью даже тогда,
          когда находятся далеко от дома, например в отпуске или командировке.
        </p>
        <p className="car-opening-paragraph">
          Изначально сотрудник компании подберет подходящую модель врезного
          замка. Затем выбирает способ установки. Монтаж выполняется с разбором
          полотна или без него. В конце проводит контрольное тестирование двери
          на мягкое закрывание и прилегание. Специалисты при выборе
          функциональных узлов учитывают предпочтения заказчика, технические
          характеристики, степень взломостойкости, и лишь после этого предлагает
          эффективное решение.
        </p>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default LocksInstallationMetall;
