import MauerRedlineL52 from "../../../assets/images/catalog/lock-cylinder/mauer-redline-l52.jpg";
import MauerRedlineL82 from "../../../assets/images/catalog/lock-cylinder/mauer-redline-l82.jpg";
import AbloyNovel from "../../../assets/images/catalog/lock-cylinder/abloy-novel.jpg";
import AbloyProtec from "../../../assets/images/catalog/lock-cylinder/abloy-protec.jpg";
import AbusKD6 from "../../../assets/images/catalog/lock-cylinder/abus-kd6.jpg";
import AbusXP2 from "../../../assets/images/catalog/lock-cylinder/abus-xp2.jpg";
import ApecsSC from "../../../assets/images/catalog/lock-cylinder/apecs-sc.jpg";
import ApecsXD from "../../../assets/images/catalog/lock-cylinder/apecs-xd.jpg";
import ApecsXS from "../../../assets/images/catalog/lock-cylinder/apecs-xs.jpg";
import CisaAstralS from "../../../assets/images/catalog/lock-cylinder/cisa-astral-s.jpg";
import CisaAP3S from "../../../assets/images/catalog/lock-cylinder/cisa-ap3-s.jpg";
import CisaRS3S from "../../../assets/images/catalog/lock-cylinder/cisa-rs3-s.jpg";
import KaleKilit from "../../../assets/images/catalog/lock-cylinder/kale-kilit-1.jpg";
import KaleKilit1 from "../../../assets/images/catalog/lock-cylinder/kale-kilit-2.jpg";
import Mottura31 from "../../../assets/images/catalog/lock-cylinder/mottura-champions-31.jpg";
import Mottura38 from "../../../assets/images/catalog/lock-cylinder/mottura-champions-38.jpg";
import Mottura48 from "../../../assets/images/catalog/lock-cylinder/mottura-champions-48.jpg";
import Multlock7_7 from "../../../assets/images/catalog/lock-cylinder/multlock-7x7.jpg";
import MultlockClassic from "../../../assets/images/catalog/lock-cylinder/multlock-classic.jpg";
import MultlockInteractive from "../../../assets/images/catalog/lock-cylinder/multlock-interactive.jpg";
import MultlockMT5 from "../../../assets/images/catalog/lock-cylinder/multlock-mt5.jpg";
import MultlockSvetofor from "../../../assets/images/catalog/lock-cylinder/multlock-svetofor.jpg";

export const paginationData = [
  [
    {
      imgSrc: MauerRedlineL52,
      lockTitle: "Цилиндр замка MAUER RED LINE L52/ 21-31",
      props: [
        "Степень секретности 85 000 000 комбинаций.",
        "Секретная кодовая пин-карта для выпуска дубликата.",
        "Запатентованная защита от вскрытия бампингом, отмычками. Невозможно высверлить или сломать / переломить корпус.",
        "Ключи мельхиоровые 5+1.",
      ],
    },
    {
      imgSrc: MauerRedlineL82,
      lockTitle: "Цилиндр замка MAUER RED LINE L82/ 31-51",
      props: [
        "Высшая степень секретности.",
        "Комплект 5 основных мельхиоровых ключей + 1 перекодировочный.",
        "Секретная кодовая пин-карта для выпуска дубликата",
        "Запатентованная защита от вскрытия бампингом, отмычками. Невозможно высверлить или сломать корпус.",
      ],
    },
    {
      imgSrc: AbloyNovel,
      lockTitle: "Цилиндр замка ABLOY NOVEL",
      props: [
        "Диск цилиндра изготовлен из закаленной стали и имеет защиту от высверливания.",
        "Вставка из закаленной стали защищает корпус цилиндра от разлома",
        "Персональная карта с зашифрованным кодом поддерживает высокий уровень секретности замкового цилиндра",
        "Абсолютная защита от бампинга, вскрытия отмычками и дублирования ключа. В комплекте 3 ключа и кодовая карта.",
      ],
    },
  ],
  [
    {
      imgSrc: AbloyProtec,
      lockTitle: "Цилиндр замка ABLOY PROTEC",
      props: [
        "Абсолютная защита от бампинга, вскрытия отмычками, бокового слома, дублирования ключа.",
        "Самый высокий уровень секретности поддерживается персональной кодовой карточкой с зашифрованным кодом.",
        "Цилиндр замка ABLOY PROTEC принадлежит к классу Extra Security.",
        "В комплекте 3 ключа и кодовая карта.",
      ],
    },
    {
      imgSrc: AbusKD6,
      lockTitle: "Цилиндр замка ABUS KD6",
      props: [
        "Цилиндр имеет 6 активных пинов, защиту от высверливания.в",
        "В комплекте: цилиндр, винт стопорный, 5 перфорированных ключей.",
        "Цвет на выбор: никель, латунь, старая бронза.",
      ],
    },
    {
      imgSrc: AbusXP2,
      lockTitle: "Цилиндр замка ABUS XP2",
      props: [
        "Запатентованная система цилиндра обладает высокой степенью защиты от бампинга, от высверливания.",
        "Имеет 12 независимых активных пинов в трех плоскостях. Аналог Cisa AP3.",
        "В комплекте: цилиндр, винт стопорный, 5 перфорированных ключей, кодовая карта",
      ],
    },
  ],
  [
    {
      imgSrc: ApecsSC,
      lockTitle: "Цилиндр замка APECS SC",
      props: [
        "Цилиндры APECS серии SC имеют закаленный стержень против васверливания.",
        "Два пина с проточкой защищают от вскрытия отмычками.",
        "В комплекте: цилиндр, винт стопорный, 5 перфорированных ключей.",
        "Цвет на выбор: никель, латунь, старая бронза. Секретность - 15.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: ApecsXD,
      lockTitle: "Цилиндр замка APECS XD",
      props: [
        "Цилиндры снабжены вставкой из закаленной стали в нижней части цилиндра, что защищает от излома или выдергивания, 2 штыря в сердечнике защищают от высверливания, 14 пинов дают защиту от подбора ключей и отмычек.",
        "В комплекте: цилиндр, винт стопорный, 5 перфорированных ключей, карта владельца.",
        "Цвет на выбор: никель, латунь, старая бронза.",
        "Секретность - 8.000.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: ApecsXS,
      lockTitle: "Цилиндр замка APECS XS",
      props: [
        "Цилиндры имеют 18 пинов против отмычек, штырь из закаленной стали против высверливания, 2 стальных пина против вытягивания, в основании цилиндра тройная пластина из стали против разрыва и излома.",
        "В комплекте: цилиндр, винт стопорный, 5 ключей, карта владельца.",
        "Цвет на выбор: никель, латунь.",
        "Секретность - 68.000.000.000 комбинаций ключа.",
      ],
    },
  ],
  [
    {
      imgSrc: CisaAstralS,
      lockTitle: "Цилиндр замка CISA ASTRAL S",
      props: [
        "Запатентованная система кодировки цилиндра обладает средней степенью защиты но, вставка SIGILLO, в нижней части цилиндра защищает от вырывания и выкручивания.",
        "Имеет кодовую систему из 10 пинов.",
        "Комплект: кодовая карта, 5 ключей.",
        "Секретность - 1.600.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: CisaAP3S,
      lockTitle: "Цилиндр замка CISA AP3 S",
      props: [
        "Запатентованная система кодировки цилиндра обладает высокой степенью защиты от бампинга, вставка SIGILLO, в нижней части цилиндра защищает от вырывания и излома.",
        "Имеет 12 независимых активных пинов.",
        "Аналог Abus XP2.",
        "Комплект: кодовая карта, 5 ключей, с подпружиненным пином.",
        "Секретность - 40.000.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: CisaRS3S,
      lockTitle: "Цилиндр замка CISA RS3 S",
      props: [
        "Лучший цилиндр Сisa RS3 обладает высочайшей степенью защиты от бампинга, вскрытия отмычкой,вырывания, выкручивания, излома.",
        "Имеет ключ с подвижным элементом и систему кодировки из 19 независимых активных пинов.",
        "Комплект: кодовая карта, 5 ключей, с подпружиненным пином.",
        "Секретность - 8.000.000.000 комбинаций ключа.",
      ],
    },
  ],
  [
    {
      imgSrc: KaleKilit,
      lockTitle: "Цилиндр замка KALE KILIT",
      props: [
        "Цилиндры обычной степени секретности, имеют 6 латунных комбинативных пинов, что позволяет противостоять вскрытию путем подбора секретного кода.",
        "Комплект: цилиндр (никель/латунь), 5 английских ключей, стопорный винт.",
        "Секретность - 55.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: KaleKilit1,
      lockTitle: "Цилиндр замка KALE KILIT",
      props: [
        "Цилиндр Kale Kilit серии 164DBME повышенной степени секретности имеют 20 латунных пинов, что позволяет противостоять вскрытию путем подбора секретного кода, 2 штифта против высверливания, стальные пластины, проходящие через цилиндр, защищают от излома и вырывания.",
        "Комплект: цилиндр (никель/латунь), 5 перфорираванных ключей, стопорный винт.",
        "Секретность - 5.000.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: Mottura31,
      lockTitle: "Цилиндр замка MOTTURA CHAMPIONS 31 серии",
      props: [
        "Цилиндры в корпусе оснащены усиленной пружинной скобой, что обеспечивает устойчивость к излому и вырыванию.",
        "Имеют 5 активных пинов, магнитный пин и магнитную вставку на ключе.",
        "Комплект: кодовая карта (защита от дублирования), 5 ключей из никель-серебряного сплава, стопорный винт.",
      ],
    },
  ],
  [
    {
      imgSrc: Mottura38,
      lockTitle: "Цилиндр замка MOTTURA CHAMPIONS 38 серии",
      props: [
        "Цилиндры в корпусе оснащены усиленной пружинной скобой, что обеспечивает устойчивость к излому и вырыванию.",
        "Имеют ключ с подвижным интерактивным элементом, который обеспечивает защиту от использования дубликатов.",
        "Комплект: кодовая карта (защита от дублирования), 5 ключей из никель-серебряного сплава, стопорный винт.",
      ],
    },
    {
      imgSrc: Mottura48,
      lockTitle: "Цилиндр замка MOTTURA CHAMPIONS 48 серии",
      props: [
        "Цилиндры Champions 48 серии самые крутые, имеют две разные системы кодировки: традиционную 5 пиновую систему и новую систему с 4 вращающимися беспружинными пинами.",
        "Так же присутствует подвижный элемент на ключе для защиты от дубликатов.",
        "Комплект: кодовая карта, 5 ключей из никель-серебряного сплава, стопорный винт.",
      ],
    },
    {
      imgSrc: Multlock7_7,
      lockTitle: `Цилиндр замка MUL-T-LOCK "7x7"`,
      props: [
        "Цилиндр 7х7 средней степени секретности отлично подходит ко всем европрофильным цилиндрическим замкам.",
        "Содержит в себе 7 одинарных пинов. Комплект: кодовая карта (защита от дублирования), 5 перфорированных ключей из никель-серебряного сплава.",
        "Секретность - 850.000 комбинаций ключа.",
      ],
    },
  ],
  [
    {
      imgSrc: MultlockClassic,
      lockTitle: `Цилиндр замка MUL-T-LOCK "CLASSIC"`,
      props: [
        "Цилиндр Classic обладает 10 телескопическими пинами.",
        "Высокая степень защиты от всех способов взлома.",
        "Комплект: кодовая карта (защита от дублирования), 5 перфорированных ключей из никель-серебряного сплава.",
        "Секретность - 3.200.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: MultlockInteractive,
      lockTitle: `Цилиндр замка MUL-T-LOCK "INTERACTIVE"`,
      props: [
        "Цилиндр Interactive очень похож на предыдущую модель Classic, но снабжен собственным уникальным элементом - подвижным пином в ключе.",
        "Комплект: кодовая карта (защита от дублирования), 5 перфорированных ключей из никель-серебряного сплава.",
        "Секретность - 3.800.000 комбинаций ключа.",
      ],
    },
    {
      imgSrc: MultlockMT5,
      lockTitle: `Цилиндр замка MUL-T-LOCK "MT5+"`,
      props: [
        "Цилиндр MT5+ имеет высочайшую степень секретности, 10 телескопических пинов и три независимые системы запирания.",
        "Плоский двусторонний ключ с запатентованным профилем нарезок.",
        "Комплект: кодовая карта (защита от дублирования), 5 перфорированных ключей из никель-серебряного сплава.",
        "Секретность - 164.916.300 комбинаций ключа.",
      ],
    },
  ],
  [
    {
      imgSrc: MultlockSvetofor,
      lockTitle: `Цилиндр замка MUL-T-LOCK "СВЕТОФОР"(CLASSIC)`,
      props: [
        "Цилиндр Светофор - это модель Classic, но с функцией перекодировки, возможен двукратный переход на новый комплект ключей, не меняя цилиндр замка.",
        "Комплект: кодовая карта, ключи: 2+5+2 или 5+5+5 (зеленый, желтый и красный комплект).",
        "Комплект: кодовая карта (защита от дублирования), 5 перфорированных ключей из никель-серебряного сплава.",
        "Секретность - 300.000.000 комбинаций ключа.",
      ],
    },
  ],
];
