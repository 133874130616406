import React from "react";
import "./style.scss";
import DoorRepairingMetallImage from "../../../../assets/images/services/door-repairing2.png";

const DoorRepairingMetall = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт дверей</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Необходима замена дверного цилиндра?</h2>
        <p className="car-opening-paragraph">
          Такое может случиться по разным причинам:
        </p>
        <ul>
          <li>механизм перестал работать</li>
          <li>утеряны запирающие устройства</li>
          <li>ключ сломался и остался внутри личинки</li>
        </ul>
        <img
          className="car-opening-image"
          src={DoorRepairingMetallImage}
          alt="Картинка услуги"
        />

        <p className="car-opening-paragraph">
          Не спешите менять замок целиком. Специалисты нашей службы приедут в
          назначенное время, осмотрят конструкцию и, если это возможно, заменят
          только цилиндр.
        </p>

        <p className="car-opening-paragraph">
          Современный рынок предлагает множество личинок разного ценового
          диапазона. Чем же отличаются бюджетные цилиндры от дорогих вариантов?
        </p>
        <ul>
          <li>
            <h4>Материалом</h4>
            <p className="car-opening-paragraph">
              Корпус доступных по стоимости китайских личинок сделан чаще всего
              из алюминия. Это мягкий деформирующийся металл. «Тело» цилиндров
              средней и высокой цены производят из более прочных материалов:
              латунь, сталь, титан.
            </p>
          </li>
          <li>
            <h4>Секретностью</h4>
            <p className="car-opening-paragraph">
              Секретность — это количество уникальных кодов, необходимых для
              вскрытия двери. Чем дороже личинка, тем больше разновидностей
              шифров предлагает производитель.
            </p>
          </li>
          <li>
            <h4>Защитой от манипуляционного и вандального взлома</h4>
            <p className="car-opening-paragraph">
              Бюджетные варианты цилиндров легко поддаются выбиванию, вскрытию
              отмычкой и высверливанию. Более дорогие механизмы имеют высокую
              степень защиты.
            </p>
          </li>
        </ul>
        <p className="car-opening-paragraph">
          Кроме всего упомянутого, на эксплуатацию личинки влияет качество
          установки или замены устройства.
        </p>
        <p className="car-opening-paragraph">
          Команда «Моscow-lock» предлагает:
        </p>
        <ul>
          <li>Профессиональный совет по выбору цилиндра</li>
          <li>Обширный ассортимент личинок</li>
          <li>Бесплатный выезд к клиенту по всей Москве и московской области</li>
          <li>Гарантию на выполненные работы</li>
        </ul>
        <p className="car-opening-paragraph">
        Для консультации или вызова мастера звоните по телефону <a href="tel:+79687110240">+7(968)711-02-40</a>.

        </p>
      </section>
    </main>
  );
};

export default DoorRepairingMetall;
