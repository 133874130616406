import ImageZaz_4_6 from "../../../assets/images/catalog/lock-min/zaz_4_6.jpg";
import Sho_25 from "../../../assets/images/catalog/lock-min/sho-25.jpg";
import Zenit3B4 from "../../../assets/images/catalog/lock-min/zenit-3b4.jpg";
import Zvad1_01 from "../../../assets/images/catalog/lock-min/zvad-1-01.jpg";
import Prosam_3b431 from "../../../assets/images/catalog/lock-min/prosam-3b431.jpg";
import Gardian_3b from "../../../assets/images/catalog/lock-min/gardian-3b.jpg";
import Mettem_3b8 from "../../../assets/images/catalog/lock-min/mettem-3b8.jpg";
import Kremen from "../../../assets/images/catalog/lock-min/kremen.jpg";
import Granit from "../../../assets/images/catalog/lock-min/granit.jpg";
import Border_81000_prosam from "../../../assets/images/catalog/lock-min/border-81000-prosam.jpg";
import Kale_kilit from "../../../assets/images/catalog/lock-min/kale-kilit.jpg";
import Class from "../../../assets/images/catalog/lock-min/class.jpg";
import Class1 from "../../../assets/images/catalog/lock-min/class-s-tyagami.jpg";
import Selmash from "../../../assets/images/catalog/lock-min/selmash.jpg";
import Selmash1 from "../../../assets/images/catalog/lock-min/selmash-s-rolikom.jpg";
import Kemz from "../../../assets/images/catalog/lock-min/kemz.jpg";
import Kale_kilit1 from "../../../assets/images/catalog/lock-min/kale-kilit1.jpg";
import Bazalt from "../../../assets/images/catalog/lock-min/bazalt.jpg";

export const paginationData = [
  [
    {
      imgSrc: ImageZaz_4_6,
      lockTitle: "ЗАЗ 4-6 МОТОР СИЧ",
      props: [
        "Цвет: медь",
        "Механизм: цилиндровый",
        "Количество ключей: 4 шт",
        "Комплектация: с ручками, цилиндром",
        "Способ установки: врезной",
        "Производство: Запорожье",
      ],
    },
    {
      imgSrc: Sho_25,
      lockTitle: "ШО-25",
      props: [
        "Цвет: хром",
        "Механизм: полуавтоматический",
        "Количество ключей: 5 шт",
        "Комплектация: с ручками, цилиндром",
        "Способ установки: врезной",
        "Производство: Беларусь",
      ],
    },
    {
      imgSrc: Zenit3B4,
      lockTitle: "ЗЕНИТ ЗВ4 - 3.01",
      props: [
        "Цвет: на выбор",
        "Механизм: цилиндровый",
        "Количество ключей: 4 шт",
        "Комплектация: с ручками, цилиндром",
        "Способ установки: врезной",
        "Производство: г.Димитровград",
      ],
    },
  ],
  [
    {
      imgSrc: Zvad1_01,
      lockTitle: "ЗВАД 1-01",
      props: [
        "Цвет: на выбор",
        "Механизм: дисковый",
        "Количество ключей: 4 шт",
        "Комплектация: с ручками, цилиндром",
        "Способ установки: врезной",
        "Производство: г. Ковров",
      ],
    },
    {
      imgSrc: Prosam_3b431,
      lockTitle: "ПРОСАМ ЗВ431",
      props: [
        "Цвет: на выбор",
        "Механизм: цилиндровый",
        "Количество ключей: 4 шт",
        "Комплектация: с ручками, цилиндром",
        "Способ установки: врезной",
        "Производство: г. Рязань",
      ],
    },
    {
      imgSrc: Gardian_3b,
      lockTitle: "ГАРДИАН ЗВ 10.01",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 4 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
  ],
  [
    {
      imgSrc: Mettem_3b8,
      lockTitle: "МЕТТЭМ ЗВ8",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 4 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
    {
      imgSrc: Kremen,
      lockTitle: "КРЕМЕНЬ",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
    {
      imgSrc: Granit,
      lockTitle: "ГРАНИТ",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный с задвижкой",
        "Количество ключей: 5 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
  ],
  [
    {
      imgSrc: Border_81000_prosam,
      lockTitle: "BORDER 81000 ПРОСАМ",
      props: [
        "Цвет накладок: темный",
        "Механизм: сувальдный",
        "Количество ключей: 3 шт",
        "Комплектация: накладки",
        "Способ установки: врезной",
        "Производство: г. Рязань",
      ],
    },
    {
      imgSrc: Kale_kilit,
      lockTitle: "KALE KILIT",
      props: [
        "Цвет накладок: латунь",
        "Механизм: крестообразный",
        "Количество ключей: 5 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: врезной",
        "Производство: Турция",
      ],
    },
    {
      imgSrc: Class,
      lockTitle: "КЛАСС",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: врезной",
        "Производство: г. Железнодорожный",
      ],
    },
  ],
  [
    {
      imgSrc: Class1,
      lockTitle: "КЛАСС С ТЯГАМИ",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: врезной",
        "Производство: г. Железнодорожный",
      ],
    },
    {
      imgSrc: Selmash,
      lockTitle: "СЕЛЬМАШ",
      props: [
        "Цвет накладок: хром",
        "Механизм: крестообразный",
        "Количество ключей: 5 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: врезной",
        "Производство: г. Киров",
      ],
    },
    {
      imgSrc: Selmash1,
      lockTitle: "СЕЛЬМАШ с роликом",
      props: [
        "Цвет накладок: темный",
        "Механизм: цилиндровый",
        "Количество ключей: 5 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
        "Производство: г. Киров",
      ],
    },
  ],
  [
    {
      imgSrc: Kemz,
      lockTitle: "КЭМЗ",
      props: [
        "Цвет накладок: темный",
        "Механизм: цилиндровый",
        "Количество ключей: 5 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: накладной",
        "Производство: г. Калуга",
      ],
    },
    {
      imgSrc: Kale_kilit1,
      lockTitle: "KALE KILIT",
      props: [
        "Цвет: на выбор",
        "Механизм: цилиндровый",
        "Количество ключей: 3 шт",
        "Комплектация: накладки, ответная планка",
        "Способ установки: накладной",
        "Производство: Турция",
      ],
    },
    {
      imgSrc: Bazalt,
      lockTitle: "БАЗАЛЬТ",
      props: [
        "Цвет накладок: хром",
        "Механизм: сувальдный",
        "Количество ключей: 5 шт",
        "Комплектация: ответная планка, накладки",
        "Способ установки: врезной",
      ],
    },
  ],
];
