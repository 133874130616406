import React from "react";
import "./style.scss";
import LocksInstallationSteelImage from "../../../../assets/images/services/cylinder5.png";

const DoorRepairingSteel = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт дверей</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт пластиковых дверей</h2>
        <p className="car-opening-paragraph">
          Предлагаем качественный ремонт пластиковой двери на месте с выездом в
          день обращения по Москве и области. Достаточно вызвать мастера на дом,
          а специалист выполнит реставрационные работы профессионально,
          аккуратно, по аргументированным ценам. Привезем сварку, слесарные
          инструменты и замки для замены с собой.
        </p>
        <img
          className="car-opening-image"
          src={LocksInstallationSteelImage}
          alt="Картинка услуги"
        />
        <h3>Отремонтируем металлическую дверь за один выезд мастера</h3>

        <p className="car-opening-paragraph">
          Даже небольшие повреждения дверного замка или перекос петли выбивают
          владельцев из колеи. Поэтому некоторые прибегают к рискованным
          способам, проводя ремонт входной пластиковой двери в квартире
          самостоятельно. Монтаж, регулировка, замена изношенных узлов – работы,
          требующие высокой квалификации и аккуратности. Поэтому лучше поручить
          исполнение специалистам.
        </p>
        <p className="car-opening-paragraph">
          <h4>Возможно вы столкнулись с проблемой:</h4>
          <ul>
            <li>Механические повреждения двери</li>
            <li>
              Полотно потеряло презентабельный вид или не сочетается с новым
              интерьером
            </li>
            <li>Выход из строя дверной фурнитуры</li>
            <li>Дверь скрипит, проседает</li>
            <li>Деформация полотна, перекос</li>
            <li>
              Проблемы с дверными петлями, уплотнителем или какие-то другие
              изъяны
            </li>
          </ul>
          <h4>Звоните нам! Мы поможем решить вашу проблему!</h4>
          <p className="car-opening-paragraph">
            Мы предоставим рациональные решения по ремонту дверей, быстро,
            качественно, не дорого, с выездом на место проведения работ.
          </p>
        </p>
        <h4>Стоимость ремонта зависит от обьема и сложности работ.</h4>
      </section>
    </main>
  );
};

export default DoorRepairingSteel;
