import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./style.scss";
import { paginationData } from "./data";

const LocksVip = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  return (
    <main className="locks-min-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>VIP замки</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Каталог замков премиум-класса</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Самые надежные замки для металлических дверей Премиум-класса
            представлены на отечественном рынке, в первую очередь, мировыми
            ведущими компаниями замочно-скобяной продукции с высокой степенью
            секретности механизмов, такими как: ATRA, ABUS, CISA, MOTTURA,
            MUL-T-LOCK, KABA, EVVA, SECUREMME и др.
          </p>
          <p className="catalog-locks-paragraph">
            Данная категория предполагает использование исключительно надежных
            замков. Надежные дверные замки данных производителей невозможно
            открыть отмычкой или подбором ключей, количество секретных
            комбинаций подбора доходит до нескольких миллионов.
          </p>
          <p className="catalog-locks-paragraph">
            Цилиндры производятся из кислотно-износостойкого металла и
            оснащены победитовыми стержнями и стальными шариками, защищающими
            механизм замка от высверливания.
          </p>
          <p className="catalog-locks-paragraph">
            Ценовая политика - от 7000 рублей. Любой каприз за Ваши деньги!
          </p>
          <div className="my-pagination">
            <ul className="pagination-page">
              {paginationData[paginationPage - 1].map((dataItem) => (
                <li className="pagination-page-list-item">
                  <img
                    src={dataItem.imgSrc}
                    className="catalog-lock-image"
                    alt="Премиум замок"
                  />
                  <div className="catalog-lock-item-info">
                    <h3 className="catalog-lock-item-info-title">
                      {dataItem.lockTitle}
                    </h3>
                    <ul className="catalog-lock-item-info-properties">
                      {dataItem.props.map((prop) => (
                        <li>{prop}</li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              className="catalog-pagination"
              page={paginationPage}
              onChange={(event, value) => {
                setPaginationPage(value);
              }}
              count={paginationData.length}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default LocksVip;
