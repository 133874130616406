import React from "react";
import { Link } from "react-router-dom";
import LockMinLogo from "../../assets/images/catalog/lock-min-logo.jpg";
import LockMedLogo from "../../assets/images/catalog/lock-med-logo.jpg";
import LockVipLogo from "../../assets/images/catalog/lock-vip-logo.jpg";
import LockCylinderLogo from "../../assets/images/catalog/lock-cylinder-logo.jpg";
import DoorHandleLogo from "../../assets/images/catalog/door-handle-logo.jpg";
import LockOverlayLogo from "../../assets/images/catalog/lock-overlay-logo.jpg";
import "./style.scss";

const CatalogPage = () => {
  return (
    <main>
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Каталог</h1>
        </div>
      </section>
      <section className="catalog">
        <ul className="catalog-list">
          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Бюджетные замки</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={LockMinLogo}
                  alt="Бюджетные замки"
                />
                <p className="catalog-list-item-summary">
                  Замки в этом ценовом сегменте высокого качества. Поскольку
                  производятся в СНГ, не требуют больших таможенных и
                  транспортных затрат, что позволяет производителю держать
                  приятную цену на свой товар.
                </p>
              </div>
              <Link className="catalog-list-item-btn" to="/catalog/locks-min">
                Подробнее
              </Link>
            </div>
          </li>

          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Замки Комфорт-класса</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={LockMedLogo}
                  alt="Замки Комфорт-класса"
                />
                <p className="catalog-list-item-summary">
                  Доступные замки с улучшенными характеристиками эконом-класса,
                  замки Комфорт-класса отличает более высокое качество, а по
                  отношению к Премиум-классу цены не кусается.
                </p>
              </div>
              <Link className="catalog-list-item-btn" to="/catalog/locks-med">
                Подробнее
              </Link>
            </div>
          </li>

          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Замки Премиум-класса</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={LockVipLogo}
                  alt="Премиум-замки"
                />
                <p className="catalog-list-item-summary">
                  Замки Премиум-класса рассчитанны клиентов, ценящих высокое
                  качество безопасности, защищенности и взломостойкости своих
                  квартир. Замки Премиум-класса стоят дороже, но результат
                  оправдывает средства на 100%.
                </p>
              </div>
              <Link className="catalog-list-item-btn" to="/catalog/locks-vip">
                Подробнее
              </Link>
            </div>
          </li>

          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Цилиндры замка</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={LockCylinderLogo}
                  alt="Цилиндры замков"
                />
                <p className="catalog-list-item-summary">
                  Цилиндры для замков являются одной из важнейших
                  частей замкового механизма, которые обеспечивают защиту
                  металлической двери от незаконного проникновения.
                </p>
              </div>
              <Link
                className="catalog-list-item-btn"
                to="/catalog/lock-cylinder"
              >
                Подробнее
              </Link>
            </div>
          </li>

          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Дверные ручки</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={DoorHandleLogo}
                  alt="Дверные ручки"
                />
                <p className="catalog-list-item-summary">
                  Дверные ручки - это часть запирающе/отпирающего механизма
                  замка. Входная дверь в обязательном порядке нуждается в
                  установке двусторонних ручек. Предпочтительно, чтобы ручки для
                  металлических дверей были основательными, со специальными
                  защитными накладками из закаленной стали, препятствующими
                  выбиванию и выламыванию цилиндра замка.
                </p>
              </div>
              <Link className="catalog-list-item-btn" to="/catalog/door-handle">
                Подробнее
              </Link>
            </div>
          </li>

          <li className="catalog-list-item">
            <h2 className="catalog-list-item-title">Броненакладки</h2>
            <div className="catalog-list-item-info">
              <div>
                <img
                  className="catalog-list-item-image"
                  src={LockOverlayLogo}
                  alt="Броненакладки"
                />
                <p className="catalog-list-item-summary">
                  Защитные броненакладки значительно повышают взломостойкость
                  замков и помогают сохранить замок от вытягивания,
                  высверливания, выбивания, выкручивания или излом запорных
                  дверных механизмов.
                </p>
              </div>
              <Link className="catalog-list-item-btn" to="/catalog/overlays">
                Подробнее
              </Link>
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default CatalogPage;
