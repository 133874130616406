import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/lock-main-logo1.png";
import "./style.scss";

// add mui Drawer instead burger men
const AppHeader = () => {
  return (
    <>
      <Navbar  expand="lg" variant="dark" className="custom-navbar">
        <Link className="brand-logo" to="/">
          <img src={Logo} alt="logo"/>
        </Link>
        <a className="header-phone shimmer" href="tel:+79687110240">+7(968)711-02-40</a>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="custom-toggler" />
        <Navbar.Collapse id="basic-navbar-nav" className="custom-nav-items">
          <Nav className="custom-nav">
            <Nav.Link href="#">
              <Link to="/" className="custom-nav-item">
                Главная
              </Link>
            </Nav.Link>
            {/* <Nav.Link href="#">
              <Link to="/services" className="custom-nav-item">
                Услуги
              </Link>
            </Nav.Link> */}
            <Nav.Link href="#">
              <Link to="/services/door-opening" className="custom-nav-item">
                Вскрытие замков
              </Link>
            </Nav.Link>
            <Nav.Link href="#">
              <Link to="/services/locks-replacement-entrance" className="custom-nav-item">
                Замена замков
              </Link>
            </Nav.Link>
            <Nav.Link href="#">
              <Link to="/services/door-repairing-entrance" className="custom-nav-item">
                Ремонт дверей
              </Link>
            </Nav.Link>
            {/* <Nav.Link>
              <Link to="/catalog" className="custom-nav-item">
                Каталог
              </Link>
            </Nav.Link> */}
            <Nav.Link>
              <Link to="/portfolio" className="custom-nav-item">
                Наши работы
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AppHeader;
