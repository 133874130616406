import React from "react";
import "./style.scss";
import DoorHingesImage from "../../../../assets/images/services/door-hinges.png";

const DoorRepairingHinges = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт дверей</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт петель</h2>
        <p className="car-opening-paragraph">
          Часто владельцы квартир просто не замечают повреждений петель. Их
          видимые дефекты не так очевидны, как сломанный замок или дверная
          ручка.
        </p>
        <p className="car-opening-paragraph">
          Предлагаем качественный ремонт петель на месте с выездом в день
          обращения по Москве и области. Достаточно вызвать мастера на дом, а
          специалист выполнит работу профессионально, аккуратно, по
          аргументированным ценам.
        </p>
        <img className="car-opening-image" src={DoorHingesImage}  alt="Картинка услуги"/>
        <h3>Ремонт петель входной двери</h3>
        <p className="car-opening-paragraph">
          Перекос или отрыв петли - довольно частая проблема, с которой наши
          мастера связываются постоянно.
        </p>
        <p className="car-opening-paragraph">
          Если вы обнаружили, что дверь стала провисать, с трудом закрываться,
          то, скорее всего, необходимо переварить петлю или осуществить ее
          ремонт. От качества и монтажа этих элементов и дверной фурнитуры
          зависит длительность эксплуатации, надежность самого полотна. В редких
          случаях, возможно, нужно будет заменить петлю.
        </p>
        <h4>Наша компания может помочь вам осуществить:</h4>
        <ul>
          <li>Замена петель на входных дверях</li>
          <li>Регулировка элементов</li>
          <li>Ремонт петли металлической двери</li>
          <li>
            Осуществление сварочных работ, грамотно приварить или переварить
            петлю железной двери;
          </li>
        </ul>

        <p className="car-opening-paragraph">
          Если вы заметили, что петля скрипит, не работает или вовсе отвалилась,
          не игнорируйте проблему! Мастерам службы под силу ликвидировать
          повреждение быстро и профессионально
        </p>
        <h4>Стоимость ремонта зависит от обьема и сложности работ</h4>
      </section>
    </main>
  );
};

export default DoorRepairingHinges;
