import React from "react";
import "./style.scss";
import LocksReplacementImage3 from "../../../../assets/images/services/locks-replacement3.png";

const LocksReplacementSteel = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Замена замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Замена замков в пластиковой двери</h2>
        <p className="car-opening-paragraph">
          Поломка запорного механизма вызывает стресс у владельца квартиры. Даже
          новый и качественный он может сломаться вследствие заводского брака.
          Заменить замок пластиковой двери по низким ценам с выездом мастера можно
          в течение часа. Обслуживаем Москву и область, привозим больше 200
          моделей, подберем по размерам.
        </p>
        <h3>Качественная замена замков в пластиковой двери на месте</h3>
        <p className="car-opening-paragraph">
          Причины, почему разумно поменять замок в пластиковой двери:
        </p>
        <ul>
          <li>Утерян единственный комплект ключей</li>
          <li>Запор устарел</li>
          <li>Была попытка взлома</li>
          <li>Не подлежит ремонту или восстановлению</li>
          <li>сменить ключи в квартире после квартирантов</li>
        </ul>
        <img
          className="car-opening-image"
          src={LocksReplacementImage3}
          alt="Картинка услуги"
        />
        <h3>Выезд мастера для замены замка пластиковой двери</h3>
        <p className="car-opening-paragraph">
          Среднее время ожидания мастера по Москве составляет не несколько
          часов, а буквально 40 минут. Это оценят занятые люди, у которых нет
          лишнего времени.
        </p>
        <p className="car-opening-paragraph">
          В 99% случаев работы по замене выполняются за один выезд на квартиру.
          Привозим все оборудование, инструменты и качественные модели с
          высокими показателями взломостойкости.
        </p>

        <p className="car-opening-paragraph">
          В большинстве случаев не нужно разбирать дверное полотно, чтобы
          заменить запорный механизм. Достаточно демонтажа старой модели, затем
          монтажа на полотно. Конечно, это в том случае, когда подобраны
          комплектующие, подходящие по размерам и удалению замочной скважины.
        </p>
        <p className="car-opening-paragraph">
          Непосредственно после установки, специалист сервисной службы проверяет
          функциональность, тестирует работоспособность. После завершения работ
          вы получите подробную консультацию по защите от взлома.
        </p>

        <p className="car-opening-paragraph">
          Мы знаем, что не всегда есть время ждать мастера, поэтому
          <span className="bold-text"> экономим Ваше время</span> и
          незамедлительно приезжаем к Вам
        </p>
      </section>
    </main>
  );
};

export default LocksReplacementSteel;
