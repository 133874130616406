import React from "react";
import "./style.scss";
import LocksInstallationSteelImage from "../../../../assets/images/services/cylinder5.png";

const CylinderRepairing = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Цилиндр замка</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт цилиндра замка</h2>
        <p className="car-opening-paragraph">
          Даже если вы используете самые качественные механизмы, со временем они
          изнашиваются. Главное вовремя заказать ремонт цилиндра замка в квартире
          или офисе. Своевременно отремонтированный цилиндр сохранит деньги,
          ведь не нужно приобретать новый механизм или взламывать заклинившую
          железную конструкцию.
        </p>
        <img
          className="car-opening-image"
          src={LocksInstallationSteelImage}
          alt="Картинка услуги"
        />
        <h3>Ремонт цилиндра дверного замка в день вызова</h3>

        <h4>Возможно вы столкнулись с одной из следующих проблем:</h4>
        <ul>
          <li>Заедает ключ или сломался</li>
          <li>
            Механические повреждения или действия злоумышленников привели к
            неработоспособности.
          </li>
          <li>Механизм устарел</li>
          <li>Входную дверь в квартиру пытались вскрыть третьи лица</li>
        </ul>
        <h4>
          Еще не поздно отремонтировать цилиндра замка! Звоните нам, мы поможем
          решить вашу проблему быстро и качественно!
        </h4>

        <p className="car-opening-paragraph">
          Мастер срочно приезжает на квартиру к заказчику, в каком бы районе
          Москвы он не находился. При себе у него слесарные инструменты, а также
          комплектующие материалы.
        </p>
        <p className="car-opening-paragraph">
          Мы проводим ремонт цилиндра замка входной двери, перекодировку или
          замену на надежные модели цилиндров, продлеваем срок эксплуатации
          самого запирающего устройства. С точки зрения цены это решение –
          отличный вариант для прагматичных жителей столицы.
        </p>
        <h4>
          Стоимость ремонта цилиндра замка зависит от бренда механизма и
          сложности работ.
        </h4>
      </section>
    </main>
  );
};

export default CylinderRepairing;
