import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "./style.scss";
import { paginationData } from "./data";

const Overlays = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  return (
    <main className="overlay-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Броненакладки</h1>
        </div>
      </section>
      <section className="catalog-locks-content">
        <h2>Броненакладки на замок</h2>
        <div>
          <p className="catalog-locks-paragraph">
            Бронированные накладки рекомендуется в обязательном порядке
            устанавливать для защиты цилиндровых механизмов. Они изготавливаются
            из сверхтвердых сплавов и значительно повышают взломоустойчивость
            замков. Их мощный, солидный внешний вид сам по себе, является
            хорошей профилактикой против попытки проникновения! Броненакладки
            производятся из закаленных сплавов и разделяются на врезные и
            накладные.
          </p>
          <div className="my-pagination">
            <ul className="pagination-page">
              {paginationData[paginationPage - 1].map((dataItem) => (
                <li className="pagination-page-list-item">
                  <img
                    src={dataItem.imgSrc}
                    className="catalog-lock-image"
                    alt="Броненакладка"
                  />
                  <div className="catalog-lock-item-info">
                    <h3 className="catalog-lock-item-info-title">
                      {dataItem.lockTitle}
                    </h3>
                    <ul className="catalog-lock-item-info-properties">
                      {dataItem.props.map((prop) => (
                        <li>{prop}</li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              className="catalog-pagination"
              page={paginationPage}
              onChange={(event, value) => {
                setPaginationPage(value);
              }}
              count={paginationData.length}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Overlays;
