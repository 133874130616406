import Abus from "../../../assets/images/catalog/overlay/abus.jpg";
import Apecs from "../../../assets/images/catalog/overlay/apecs1.jpg";
import Apecs1 from "../../../assets/images/catalog/overlay/apecs2.jpg";
import Cisa from "../../../assets/images/catalog/overlay/cisa1.jpg";
import Cisa1 from "../../../assets/images/catalog/overlay/cisa2.jpg";
import Cisa2 from "../../../assets/images/catalog/overlay/cisa3.jpg";
import KaleKilit from "../../../assets/images/catalog/overlay/kale-kilit1.jpg";
import KaleKilit1 from "../../../assets/images/catalog/overlay/kale-kilit2.jpg";
import Mottura from "../../../assets/images/catalog/overlay/mottura1.jpg";
import Mottura1 from "../../../assets/images/catalog/overlay/mottura2.jpg";
import Multlock from "../../../assets/images/catalog/overlay/multlock.jpg";

export const paginationData = [
  [
    {
      imgSrc: Abus,
      lockTitle: "ABUS",
      props: [
        "Ручки с бронезащитой можно установить практически на все корпуса замков, ранее установленных в металлических дверях.",
        "Защита цилиндра осуществляется за счет бронечашечки с пластиковыми проставками внутри для защиты от выбивания или слома цилиндра.",
        "Свободно вращающийся стальной диск на ручке защищает от высверливания.",
      ],
    },
    {
      imgSrc: Apecs,
      lockTitle: "Накладная броненакладка Apecs.",
      props: [
        "Серия Basic.",
        "Линия Simple.",
        "Цвета на выбор: золото, бронза, хром.",
      ],
    },
    {
      imgSrc: Apecs1,
      lockTitle: "Врезная броненакладка Apecs.",
      props: [
        "Серия Protector Pro.",
        "Линия Standart.",
        "Цвета на выбор: золото, хром.",
      ],
    },
  ],
  [
    {
      imgSrc: Cisa,
      lockTitle: "Накладная броненакладка Cisa.",
      props: [
        "Защитная броня из цементированной азотированной стали.",
        "Цвета на выбор: латунь, хром.",
      ],
    },
    {
      imgSrc: Cisa1,
      lockTitle: "Врезная броненакладка Cisa.",
      props: [
        "Защитная броня из цементированной азотированной стали.",
        "Цвета на выбор: латунь, хром.",
      ],
    },
    {
      imgSrc: Cisa2,
      lockTitle: "Перекодировочная накладка Cisa.",
      props: [
        "Секретная накладка для установки на шестисувальдные замки Cisa.",
        "Цвета на выбор: латунь, хром, никель.",
      ],
    },
  ],
  [
    {
      imgSrc: KaleKilit,
      lockTitle: "Накладная броненакладка Kale Kilit.",
      props: ["Цвета на выбор: латунь. никель."],
    },
    {
      imgSrc: KaleKilit1,
      lockTitle: "Врезная броненакладка Kale Kilit.",
      props: ["Цвет: латунь."],
    },
    {
      imgSrc: Mottura,
      lockTitle: "Врезная броненакладка Mottura.",
      props: [
        "Серия защитных броненакладок Defender Top System поставляется со съемными крышками 5 цветов.",
        "Отверстие под ключ защищено вращающейся шайбой из марганцевой стали.",
        "Цвета на выбор: хром, матовый хром, черный полимер, латунь, матовая латунь.",
      ],
    },
  ],
  [
    {
      imgSrc: Mottura1,
      lockTitle: "Накладная броненакладка Mottura.",
      props: [
        "Защитная броня из цементированной азотированной стали.",
        "Отверстие под ключ защищено вращающейся шайбой из марганцевой стали.",
        "Цвета на выбор: латунь, хром.",
      ],
    },
    {
      imgSrc: Multlock,
      lockTitle: "Накладная броненакладка Mul-T-Lock.",
      props: [
        "Рекомендуется как защита врезного замка стандарта DIN.",
        "Цвета на выбор: латунь, хром.",
      ],
    },
  ],
];
