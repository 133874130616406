import React from "react";
import "./style.scss";
import LockRepairingSteelImage from "../../../../assets/images/services/locks-replacement1.png";

const LockRepairingSteel = () => {
  return (
    <main className="car-opening-page">
      <section className="top-services-slide">
        <div className="top-text-container">
          <h1>Ремонт замков</h1>
        </div>
      </section>
      <section className="car-opening-content">
        <h2>Ремонт замков пластиковой двери</h2>
        <p className="car-opening-paragraph">
          Поломка механизма не повод покупать новый. Если за дело принимается
          опытный и надежный мастер по ремонту дверных замков, вам не стоит
          беспокоиться о восстановлении функционала
        </p>
        <img
          className="car-opening-image"
          src={LockRepairingSteelImage}
          alt="Картинка услуги"
        />
        <h3>Ремонтируем замки в пластиковых дверях за один выезд</h3>
        <h4>Частая причина проблем с замком:</h4>
        <ul>
          <li>Поломка ключа в цилиндре</li>
          <li>Установлет замок с заводским браком</li>
          <li>Несоответствие размеров цилиндра, сменного секрета</li>
          <li>Попытка взлома или вскрытия, отрыв ручки</li>
          <li>Повреждение щеколды, задвижки, дверной ручки</li>
          <li>Некачественный дубликат ключа</li>
          <li>Неправильный монтаж, перекос, просадка конструкции.</li>
        </ul>
        <p className="car-opening-paragraph">
          Перечислять причины и повреждения можно долго. Но проблема остается:
          замок пластиковой двери не работает и необходимо вызвать мастера для
          ремонта.
        </p>
        <h4>Звоните! Мы поможем вам!</h4>

        <p className="car-opening-paragraph">
          Не стоит пытаться отремонтировать замок самостоятельно, вы можете
          сделать только хуже! Гараздо лучше вызвать мастера сразу, как
          обнаружена неисправность!
        </p>
        <p className="car-opening-paragraph">
          Если замок сломался или застрял ключ, предлагаем два варианта решения.
          В первом случае мастер на месте ремонтирует его, а во втором –
          выполняет частичную замену. Что касается альтернативного способа, то
          приступает к нему, когда ремонт невозможен. Наши мастера находят самые
          разумные методы при возникновении непростых ситуаций
        </p>
        <h4>Стоимость ремонта зависит от обьема и сложности работ.</h4>
      </section>
    </main>
  );
};

export default LockRepairingSteel;
